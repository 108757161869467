import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {baseUri} from "../commoms";

const SeasonalWalletPerformance = () => {

    const [htmlwidgetData, setHtmlwidgetData] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const [sortCommands, setSortCommands] = useState([]);

    const [filterDate, setFilterDate] = useState();

    const [filter, setFilter] = useState(false);

    const refDateSelector = useRef();


    const titles = [
        {name:'Strumento', type: 'instrument', opType: null, orderable: true, show: true},
        {name:'Operazione', type: 'opType', opType: null, orderable: true, show: true},
        {name:'Valuta', type: 'text', opType: null, orderable: true, show: true},
        {name:'Data inizio', type: 'date', opType: null, orderable: true, show: true},
        {name:'Prezzo apertura operazione', type: 'number', opType: null, orderable: true, show: true},
        {name:'Data chiusura operazione', type: 'date', opType: null, orderable: true, show: true},
        {name:'Prezzo chiusura', type: 'number', opType: null, orderable: true, show: true},
        {name:'P&L % Operazione', type: 'percentage', opType: "follow", orderable: true, show: true},
        {name:'Capitale investito', type: 'number', opType: null, orderable: true, show: true},
        {name:'Nr quote', type: 'number', opType: null, orderable: true, show: true},
        {name:'Controvalore in euro', type: 'number', opType: null, orderable: true, show: true},
        {name:'P&L in euro', type: 'number', opType: null, orderable: true, show: true},
        {name:'Correlazione 1', type: 'percentage', opType: null, orderable: true, show: true},
        {name:'Correlazione 2', type: 'percentage', opType: null, orderable: true, show: true},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
        {name:'xx', type: 'percentage', opType: null, orderable: false, show: false},
    ];

    const loadPage = (event, pos, pagename) => {

        if (event)
            event.preventDefault();
        setHtmlwidgetData( [] );

        axios.get(baseUri + `/html?filename=${pagename}`, { headers: { "Accept": "application/json" } })
            .then( res => {
                let fullPage = res.data.content.join("\n");
                const parser = new DOMParser();
                let xmlPage = parser.parseFromString(fullPage, "text/html");
                const scripts = xmlPage.querySelectorAll("script");
                for( let k =0; k < scripts.length; k++ ) {
                    let script = scripts[k];
                    if (script.getAttribute("data-for")
                        && script.getAttribute("data-for").includes("htmlwidget")
                        && script.getAttribute("type").includes("json")) {
                        const data = JSON.parse(script.innerText);
                        if ( data.x) {
                            // setHtmlwidgetData( { ...htmlwidgetData, ...data.x.data} );
                            setHtmlwidgetData( [...data.x.data] );
                            setDisplayData( [...data.x.data]);
                        }
                    }
                }

            })
            .catch( err => {
                console.error(err);
            });

    }

    const sortDataImpl = (data) => {
        if (sortCommands.length > 1) {
            console.log("Soritng ...")
            const column = sortCommands[0] +1;
            const direction = sortCommands[1];
            let localData = [...data];
            let localSortedData = traspose(traspose(localData).sort( (a,b) => {
                if ( a[column] === b[column])
                    return 0;
                else if ( a[column] > b[column])
                    return direction;

                return -direction;
            }));
            return [...localSortedData];
        } else {
            console.log("Not soritng ...");
            return data;
        }
    }

    const sortData = () => {
        const lsd = sortDataImpl([...displayData]);
        setDisplayData(lsd);
    };

    const filterByDate = () => {
        if (filter) {
            console.log("Filtering");
            let localData = [...htmlwidgetData];
            let filteredLocalData = localData.map( (col) => {
                return col.filter((cell, index) => {
                    // console.log(new Date(localData[8][index]),  ">=" ,  new Date(refDateSelector.current.value), "? ", new Date(localData[8][index]) >= new Date(refDateSelector.current.value));
                    return new Date(localData[8][index]) >= new Date(refDateSelector.current.value)
                })
            });
            filteredLocalData = sortDataImpl([...filteredLocalData]);
            setDisplayData([...filteredLocalData]);
        } else {
            console.log("Not filtering");
            let filteredLocalData = sortDataImpl([...htmlwidgetData]);
            setDisplayData([...filteredLocalData]);
        }
    }

    const doSort = (sortArray) => {
        setSortCommands(sortArray);
    };

    const compareArrays = (a,b) => {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    const traspose = matrix => {if (matrix[0]) return matrix[0].map((x,i) => matrix.map(x => x[i])); else return matrix;};

    const renderHeaders = () => {
        return <thead>
        <tr>
            {
                titles.map((th, index) => {
                    let align="text-center";
                    switch (th.type) {
                        case "instrument":
                            align="text-start"
                            break;
                        case "text":
                        case "opType":
                        case "date":
                        case "link":
                        case "graph":
                        case "number":
                            align="text-center"
                            break;
                        case "percentage":
                            align="text-end";
                            break;
                        default:
                            align="text-end";
                            break;
                    }
                    return th.show ? <th key={index} className={"border-bottom sticky-top p-1 bg-light " + align}>
                        <div className={"me-1 p-1 w-100" + (sortCommands[index] === 0 ? "bg-info text-white": "")}>{ th.name }</div>
                        { th.orderable ? <>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([index,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([index,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[index] === 0 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([index,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([index,-1])}><i className="bi-sort-up"></i></button>
                        </> : <div><button className="btn btn-sm p-0 py-1">&nbsp;</button></div>
                        }
                    </th> : ""
                })
            }
        </tr>
        </thead>
    }

    const renderCell = (type, value, pos, opType, symbol) => {
        let cell = <></>
        switch (type) {
            case "text":
            default:
                cell = <td className="p-1 text-center align-middle" key={pos}>{value}</td>;
                break;
            case "instrument":
                let [s, d] = value.split("|");
                cell = <td className="p-1 text-start align-middle" key={pos}>
                    <div className='p80'>{s}</div>
                    { d ? <div><strong>{d}</strong></div> : ""}
                </td>;
                break;
            case "opType":
                cell = <td key={pos} className="text-center p-1 align-middle"><div className={"p-1 m-0 alert " + (value === "Sell" ? "alert-danger" : "alert-success")}>{value}</div></td>;
                break;
            case "percentage":
                let barColor = "bg-info";
                let colOpType = titles[pos].opType;
                if (colOpType) {
                    colOpType = titles[pos].opType.toLowerCase();
                    if (colOpType === "follow") {
                        if (opType === "sell") {
                            barColor="bg-danger";
                        } else if (opType === "buy") {
                            barColor="bg-success"
                        }
                    } else if (colOpType === opType) {
                        if (opType === "sell") {
                            barColor="bg-danger";
                        } else if (opType === "buy") {
                            barColor="bg-success"
                        }
                    } else {
                        barColor="bg-light";
                    }
                }
                let perc = Number.parseFloat(value).toLocaleString(undefined,{style:'percent', minimumFractionDigits:2});

                cell = <td key={pos} className="p-1 text-end align-middle">
                    <div className="progress text-end">
                        <div className={"progress-bar overflow-visible " + barColor} style={{"width": (Math.abs(Number.parseFloat(value)*100.0))+"%"}}><div className="text-end text-dark" style={{mixBlendMode:"color-burn"}}>{perc}</div></div>
                    </div>
                </td>;
                break;
            case "number":
                cell = <td key={pos} className="p-1 text-center align-middle">{value}</td>;
                break;
            case "date":
                let date = new Date(value);
                cell = <td key={pos} className="p-1 text-center align-middle">{date.toLocaleDateString()}</td>;
                break;
        }
        return titles[pos].show ? cell : "";

    }

    const renderRows = () => {
        console.log("Render rows");
        if (displayData.length > 0) {
            const indexArray = displayData[0];
            return indexArray.map( (element, index) => {
                return <tr key = {element}>
                    {
                        displayData.map( (col, pos)  => {
                            if (pos > 0) {
                                return renderCell(titles[pos-1].type, col[index], pos-1, displayData[2][index].toLowerCase(), displayData[1][index])
                            }
                            return "";
                        })
                    }
                </tr>
            } )
        } else {
            return <tr><td colSpan={20}><div className="alert alert-warning">NO DATA</div></td></tr>
        }
    }

    const setFilterStatus = () => {

        setFilter(false);
        const status = (refDateSelector.current.value !== "undefined")
            && (refDateSelector.current.value !== null)
            && (refDateSelector.current.value !== "");
        setFilter(status);
        if (status) {
            setFilterDate( new Date(refDateSelector.current.value));
        } else {
            setFilterDate(null);
        }
    }

    useEffect(()=>loadPage(null, 1, "RendimentoPortafoglioSeasonal.html"),[]);
    useEffect(()=> sortData(), [sortCommands]);
    useEffect( () => filterByDate(), [filterDate]);

    return <>
        <div className="grid-service d-flex flex-row align-items-center p-1">
            <div className="me-5"><h1>Seasonal wallet performance</h1></div>
        </div>
        <div className="grid-row-start-3 grid-row-end-4 grid-column-start-1 grid-column-end-3 table-responsive">
            <div className="p-5 w-100 overflow-hide">
                <div className="d-flex flex-row overflow-x-scroll">
                    <table className="table p80">
                        {
                            renderHeaders()
                        }
                        <tbody>
                        {
                            renderRows()
                        }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </>

}

export default SeasonalWalletPerformance;