import logo from '../assets/logo.png';

const Landing = () => {
    return <div className="d-flex flex-column justify-content-center grid-content align-items-center">
        <div className="display-1 mb-2">Wyckoff Position Index</div>
        <div className="d-flex flex-row justify-content-center align-items-center mb-5">
            <div className="display-5 me-3">powered by</div>
            <div>
            <img src={logo} alt="The Trading Playground - Wyckoff Position Index" className="half-image" />
            </div>
        </div>
        <div className="display-7">Select tool from menu bar</div>
    </div>
}

export default Landing;