import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { baseUri } from "../commoms";
import "../App.css";
import "../assets/dashboard.css";
import TVChart from "../components/TVChart";


const WPIChart = () => {
    const [instruments, setInstruments] = useState([]);
    const [filteredInstruments, setFilteredInstruments] = useState([]);
    const [visible, setVisible] = useState([]);
    const [xAxisLabel, setXAxisLabel] = useState("");
    const [yAxisLabel, setYAxisLabel] = useState("");
    const [loadedData, setLoadedData] = useState({});
    const [currentZoomState, setCurrentZoomState] = useState();
    let topBoundary = -200;
    let rightBoundary = -200;
    let bottomBoundary = 200;
    let leftBoundary = 200;
    const [startDateList, setStartDateList] = useState([]);
    const [startDateText, setStartDateText] = useState("");
    const [group, setGroup] = useState("#ASSET ALLOCATION");
    const [type, setType] = useState("bb");
    const [traceLength, setTraceLength] = useState(2);
    const [tvSymbol, setTvSymbol] = useState("");

    const [reload, setReload] = useState(true);
    const [redraw, setRedraw] = useState(false);

    const groupRef = useRef();
    const typeRef = useRef();
    const lastDateRef = useRef();
    const traceLengthRef = useRef();
    const graphArea = useRef();
    const instrumentSearchBox = useRef();

    // Arrow marker parameters
    const markerBoxWidth = 6;
    const markerBoxHeight = 6;
    const refX = markerBoxWidth / 2;
    const refY = markerBoxHeight / 2;
    const arrowPoints = [[0, 0], [0, 6], [6, 3], [0, 0]];

    const activateGraphOverlay = (symbol) => {
        setTvSymbol(loadedData.datas[symbol].info["tvSymbol"]);
    }

    const renderInstrumentsList = () => {
        return filteredInstruments.map( (instrument, index) => {
            return <tr key={instrument.symbol}>
                <td className="text-left">
                    <button className="btn btn-sm btn-outline text-start p80" title={"Evidenzia "+ instrument.symbol} onClick={() => enhanceVisibility(instrument.symbol)}><strong>{instrument.benchmark?<span title="Benchmark del gruppo">🔶</span>:""}{instrument.symbol}</strong> - {instrument.description}</button>
                </td>
                <td className="text-right">
                    <button className="btn btn-sm btn-outline text-end p80" onClick={() => activateGraphOverlay(instrument.symbol)} data-bs-toggle="modal" data-bs-target="#tvGraph" title={"Mostra grafico per  "+ instrument.symbol}><i className="bi-bar-chart"></i></button>
                </td>
            </tr>;
        })
    };

    let forceVisibility= (newVisibility) => {
        for (var k=0; k< visible.length; k ++) {
            visible[k] = newVisibility;
        }
    };

    let showAll = () => {
        forceVisibility(1.0);
        setRedraw(true);
    };

    let hideAll = () => {
        forceVisibility(0.0);
        setRedraw(true);
    };

    let selectQuadran = (quadran) => {

        var xAxisMag = 0;
        var yAxisMag = 0;
        switch (quadran) {
            case 1:
                xAxisMag = 1;
                yAxisMag = 1;
                break;
            case 2:
                xAxisMag = -1;
                yAxisMag = 1;
                break;
            case 3:
                xAxisMag = -1;
                yAxisMag = -1;
                break;
            case 4:
                xAxisMag = 1;
                yAxisMag = -1;
                break;
            default:
                xAxisMag = 0;
                yAxisMag = 0;
        };
        
        if (loadedData) {

            let count = 0
            instruments.forEach( pkey => {
                let key = pkey.symbol;
                if (key !== 'timeline' && key !== 'geometry') {
                    let dataV = loadedData['datas'][key]['data']
                    if (dataV.length > 1) {
                        const valX = dataV[dataV.length - 1][1]
                        const valY = dataV[dataV.length - 1][2]
                        if ((Math.sign(valX) === xAxisMag) && (Math.sign(valY) === yAxisMag)) {
                            visible[count] = 1.0;
                        } else {
                            visible[count] = 0.0;
                        }
                    }
                }
                count ++
            });

            setRedraw(true);
        }

    };


    let zoomFunction = (event) => {

        let zoomState = d3.zoomTransform(svg.node());

        setCurrentZoomState( zoomState );

        setRedraw(true);

    }

    let zoom = d3.zoom()
        .scaleExtent([0.1, 8])
        .on('zoom', zoomFunction);

    let svg = d3.select("#canvas");

    // Create arrow marker
    svg
        .append('defs')
        .append('marker')
        .attr('id', 'arrow')
        .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
        .attr('refX', refX)
        .attr('refY', refY)
        .attr('markerWidth', markerBoxWidth)
        .attr('markerHeight', markerBoxHeight)
        .attr('orient', 'auto-start-reverse')
        .append('g')
        .append('path')
        .attr('d', d3.line()(arrowPoints))
        .attr('stroke', '#333')
        .attr('fill', '#eee')
        ;


    let viewportHeight;
    let viewportWidth;

    let published = true;

    const filterInstrument = () => {

        let currentFilter = instrumentSearchBox.current?.value;

        if (currentFilter.trim() !== "") {
            setFilteredInstruments ([ ...(instruments.filter( instrument => instrument.symbol.includes(currentFilter.toUpperCase()))) ] );
        } else {
            setFilteredInstruments ([ ...instruments ] );
        }

    }

    const groupsStructure = [
        {
            "optgroup": "ASSET ALLOCATION USA",
            "groups": [
                {
                    "name": "#ASSET ALLOCATION",
                    "display": "ASSET ALLOCATION"
                }
            ]
        },
        {
            "optgroup": "DOW JONES",
            "groups": [
                {
                    "name": "#DJ INDUSTRIAL",
                    "display": "DOW JONES INDUSTRIAL"
                },
                {
                    "name":"#DJUTILITIES",
                    "display": "DOW JONES UTILITIES"
                },
                {
                    "name": "#DJTRANSPORTATION",
                    "display": "DOW JONES TRANSPORTATION"
                }
            ]
        },
        {
            "optgroup": "S&P500 SECTORS",
            "groups": [
                {"name":"S&P500 SECTORS", "display": "S&P500 SECTORS"},
                {"name":"S&P500 SECTORS EQUAL WEIGHT","display": "S&P500 SECTORS EQUAL WEIGHT"},
                {"name":"S&P500 Industrials", "display":"INDUSTRIAL SECTOR"},
                {"name":"S&P500 Health Care", "display": "HEALTH CARE SECTOR" },
                {"name":"S&P500 Utilities", "display":"UTILITIES SECTOR"},
                {"name":"S&P500 Real Estate", "display":"REAL ESTATE SECTOR"},
                {"name":"S&P500 Materials", "display":"MATERIALS SECTOR"},
                {"name":"S&P500 Financials", "display":"FINANCIALS SECTOR"},
                {"name":"S&P500 Consumer Staples", "display":"CONSUMER STAPLES SECTOR"},
                {"name":"S&P500 Energy", "display":"ENERGY SECTOR"},
                {"name":"S&P500 Consumer Discretionary", "display":"CONSUMER DISCRETIONARY SECTOR"},
                {"name":"S&P500 Communication Services", "display":"COMMUNICATION SERVICES SECTOR"},
                {"name":"S&P500 Information Technology", "display":"INFORMATION TECHNOLOGY SECTOR"}
            ]
        },
        {
            "optgroup": "DIVIDEND ARISTOCRATS",
            "groups": [
                {"name":"#DIVIDEND ARISTOCRATS", "display": "DIVIDEND ARISTOCRATS"}
            ]
        },
        {
            "optgroup": "USA MARKETS",
            "groups": [
                {"name":"USA MARKETS", "display":"USA MARKETS"}
            ]
        },
        {
            "optgroup": "WORLD STOCKS",
            "groups": [
                {"name":"#ETF INTERNAZIONALI", "display": "INTERNATIONAL MARKETS"},
                {"name":"#FTSEMIB", "display": "FTSEMIB"},
                {"name":"#DAX40", "display": "DAX40"},
                {"name":"#CAC40", "display": "CAC40"},
                {"name":"#IBEX35", "display": "IBEX35"},
                {"name":"#BEL20", "display": "BEL20"}
            ]
        },
        {
            "optgroup": "INDICI INTERNAZIONALI",
            "groups": [
                {"name":"#INDICI INTERNAZIONALI", "display": "INDICI INTERNAZIONALI"}
            ]
        },
        {
            "optgroup":"BOND",
            "groups":[
                {"name" : "BOND MATURITY", "display": "BOND MATURITY"},
                {"name" : "TREASURY", "display": "TREASURY"}
            ]
        },
        {
            "optgroup": "ITALIAN STOCKS",
            "groups": [
                {"name":"FTSE UTILITIES", "display": "FTSE UTILITIES"},
                {"name":"FTSE INDUSTRIALS", "display": "FTSE INDUSTRIALS"},
                {"name":"FTSE HEALTHCARE", "display": "FTSE HEALTHCARE"},
                {"name":"FTSE MATERIALS", "display": "FTSE MATERIALS"},
                {"name":"FTSE FINANCIALS", "display": "FTSE FINANCIALS"},
                {"name":"FTSE CONSUMER STAPLES", "display": "FTSE CONSUMER STAPLES"},
                {"name":"FTSE ENERGY", "display": "FTSE ENERGY"},
                {"name":"FTSE DISCRETIONARY", "display": "FTSE DISCRETIONARY"},
                {"name":"FTSE TECHNOLOGY", "display": "FTSE TECHNOLOGY"},
                {"name":"FTSE COMMUNICATIONS", "display": "FTSE COMMUNICATIONS"},
            ]
        },
        {
            "optgroup": "FOREX & CRYPTO",
            "groups":[
                {"name":"#FOREX MAJORS", "display": "FOREX MAJORS"},
                {"name":"#FOREX COMPLETA", "display": "FOREX CROSS"},
                {"name":"#CRIPTOVALUTE", "display": "CRYPTO"}
            ]
        },
        {
            "optgroup": "COMMODITIES",
            "groups": [
                {"name":"COMMODITIES", "display": "COMMODITIES"},
                {"name":"COMMODITIES - METALS", "display": "COMMODITIES - METALS"},
                {"name":"COMMODITIES - GRAINS & SEEDS", "display": "COMMODITIES - GRAINS & SEEDS"},
                {"name":"COMMODITIES - SOFTS", "display": "COMMODITIES - SOFTS"},
                {"name":"COMMODITIES - ENERGY", "display": "COMMODITIES - ENERGY"},
                {"name":"COMMODITIES - MEATS", "display": "COMMODITIES - MEATS"},
            ]
        }
    ];

    const resetBoundaries = ()  => {
        topBoundary = -200;
        rightBoundary = -200;
        bottomBoundary = 200;
        leftBoundary = 200;
    };

    const setBoundaries = (fullData) => {
        let _localTop = topBoundary;
        let _localBottom = bottomBoundary;
        let _localLeft  = leftBoundary;
        let _localRight = rightBoundary;

        for (let symbol in fullData) {
            if (symbol !== "timeline" && symbol !== "geometry") {
                let data = fullData[symbol].data;
                if (data.length > 1) {
                    for (let p = data.length -2; p < data.length; p++) {
                        if (_localTop === -200) {
                            _localTop= (data[p][2] + 2);
                        } else {
                            _localTop= (Math.max(_localTop, data[p][2] + 2));
                        }
                        if (_localBottom === 200) {
                            _localBottom = (data[p][2] -2);
                        } else {
                            _localBottom = (Math.min(_localBottom, data[p][2] -2));
                        }
                        if (_localRight === -200) {
                            _localRight = (data[p][1] + 2);
                        } else {
                            _localRight = (Math.max(_localRight, data[p][1] + 2));
                        }
                        if (_localLeft === 200) {
                            _localLeft = (data[p][1] - 2);
                        } else {
                            _localLeft = (Math.min(_localLeft, data[p][1] - 2));
                        }
                    }
                }
            }
        }
        bottomBoundary = _localBottom;
        topBoundary = _localTop;
        rightBoundary = _localRight;
        leftBoundary = _localLeft;
    }


    const getInstruments = (datas) => {

        let _instruments = [];
        for ( let s in datas ) {
            if ((s !== "timeline") && (s !== "geometry")) {
                _instruments.push({"symbol":s, "description": datas[s].info.description, "benchmark": datas[s].benchmark})
            }
        }

        _instruments.sort((a,b) => {
            if (a.benchmark) return -1;
            if (b.benchmark) return 1;
            if (a.symbol < b.symbol) return -1;
            if (b.symbol < a.symbol) return 1;
            return 0;
        });

        setInstruments( [ ..._instruments ] );

        setFilteredInstruments( [ ..._instruments ]);
        instrumentSearchBox.current.value = "";

    }

    const filterData = (fullDataStream) => {

        let fdt = [...fullDataStream];
        
        fdt.reverse();

        let streamLength = traceLength;

        let isoStartDate = startDateList[lastDateRef.current?.value].toISOString().slice(0, 10);

        let ltList = fdt.filter(element => {
            let dateFromElement = new Date( Date.parse(element[0])).toISOString().slice(0, 10);
            return dateFromElement <= isoStartDate
        });

        return ltList.slice(0, streamLength);
    }

    const getData = () => {

        if (!reload) return;


        const xParam = encodeURIComponent(type === "bb" ? "Breve periodo" : "Lungo periodo");
        const yParam = encodeURIComponent(type === "bb" ? "Brevissimo periodo" : "Medio periodo");
        const groupName = encodeURIComponent(group);

        setXAxisLabel(type === "bb" ? "Breve periodo" : "Lungo periodo");
        setYAxisLabel(type === "bb" ? "Brevissimo periodo" : "Medio periodo");

        d3
        .json(baseUri + `/datas?x=${xParam}&y=${yParam}&group=${groupName}&limit=80`)
        .then(function (json) {

            setLoadedData( loadedData => ({
                ...loadedData,
                ...json
            }));


            getInstruments(json?.datas);

            setVisible([]);


            let _data = json;

            let tline = _data['datas']['timeline'];
            tline.reverse();

            let _startDateList = [];

            for (let k=0; k <= 60; k++) {
                _startDateList[k] = new Date(tline[k]);
            }


            setStartDateList(_startDateList);

            setStartDateText(_startDateList[0].toLocaleDateString());

            let keys=[];
            let bk = '';

            for (let k in _data['datas']) {
                if (k !== 'geometry' && k !== 'timeline') {
                    if (!_data['datas'][k].benchmark)
                        keys.push(k);
                    else
                        bk = k;
                }
            }

            keys.sort();

            let _visible = [];

            if (bk !== '')
                keys = [bk, ...keys];

                
            for (let pos = 0; pos < keys.length; pos++) {
                
                let key = keys[pos];

                if (key !== "geometry" && key !== 'timeline') {

                    _visible[pos] = 1;

                }

            }

            setVisible(_visible);
            lastDateRef.current.value = 0;
            traceLengthRef.current.value = 2;
            setTraceLength(2);

            setReload(false);
            setRedraw(true);

        }
        )
        .catch((err) => {
            console.error("Something went horribly wrong ", err);

            }
        );


    }

    const enhanceVisibility = (enSymbol) => {

        let pos = 0
        for ( let i in instruments) {
            let instrument = instruments[i];
            if (instrument.symbol === enSymbol)
                break;
            else
                pos++
        }
        console.log(enSymbol, pos);

        let newVisibility = 0.0;
        for (let k = 0; k < visible.length; k++) {
            if ( (k !== pos) && (visible[k] !== 0.0) ) {
                newVisibility = 0.3;        
            } else if (k === pos) {
                newVisibility = 1.0;
            }
            visible[k]= newVisibility;
        }
        setRedraw(true);
    }

    const drawPlot = () => {

        //if (!redraw) return;

        let pos = 0;

        if (loadedData != null) {
            const data = loadedData['datas'];
            d3.selectAll('.trace').remove();

            let keys = [];
            let bk = '';

            for (let k in data) {
                if (k !== 'geometry' && k !== 'timeline') {
                    if (!data[k].benchmark)
                        keys.push(k);
                    else
                        bk = k;
                }
            }

            let cs = window.getComputedStyle(d3?.select('#canvas').node());

            viewportHeight = parseInt(cs.height.slice(0, -2));
            viewportWidth = parseInt(cs.width.slice(0, -2));

            svg.call(zoom);

            
            resetBoundaries();
            setBoundaries(data);

            let xScale = d3.scaleLinear()
                .domain([leftBoundary, rightBoundary])
                .range([0, viewportWidth]);

            let xAxis = d3.axisBottom(xScale).ticks(published ? 0 : 20);

            if (currentZoomState) {
                let newXScale = currentZoomState.rescaleX(xScale);
                xScale.domain(newXScale.domain()); 
            }

            svg.select("#X-AXIS").remove();
            let xac = svg.append("g").attr("id", "X-AXIS");
            xac.attr("transform", "translate(" + 0 + "," + 15 + ")");
            xac.call(xAxis);

            let yScale = d3.scaleLinear()
                .domain([bottomBoundary, topBoundary])
                .range([viewportHeight, 0]);

            let yAxis =  d3.axisRight(yScale).ticks(published ? 0 : 20);

            if (currentZoomState) {
                let newYScale = currentZoomState.rescaleY(yScale);
                yScale.domain(newYScale.domain()); 
            }

            svg.select("#Y-AXIS").remove();
            let yac = svg.append("g").attr("id", "Y-AXIS");
            yac.attr("transform", "translate(" + 15  + "," + 0 + ")");
            yac.call(yAxis);
    
            keys.sort();

            if (bk !== '')
                keys = [bk, ...keys];

            for (let i=0; i < keys.length; i++) {

                let key = keys[i];

                let benchmark = data[key].benchmark;

                let localdata = filterData(data[key].data);

                let line_color = '#2986cc'

                if (benchmark) {
                    line_color = '#e69138';
                }
                

                let g = svg.append("g")
                    .attr('id', 'line' + pos)
                    .attr('class', 'trace')
                    .style('opacity', visible[pos])
                    ;

                g.append("path")
                    .datum([...localdata].reverse())
                    .attr("fill", "none")
                    .attr("stroke", line_color)
                    .attr("stroke-width", 3)
                    .attr("d", d3.line()
                        .x((d) => xScale(d[1]) )
                        .y((d) => yScale(d[2]) )
                    )
                    .attr("marker-end", "url(#arrow)")
                    .on("click", (event) => {
                        event.preventDefault();
                        let parentNode = event.target;
                        
                        let parentId = "";
                        
                        if (parentNode.id) {
                            parentId = parentNode.id;
                        }

                        while(parentId.substring(0,4) !== 'line') {
                            if (parentNode.id) {
                                parentId = parentNode.id;
                            }

                            parentNode = parentNode.parentNode;
                        }


                        enhanceVisibility(Number(parentId.substring(4)));
                    })
                    ;


                // Add dots
                g.append('g')
                    .selectAll("dot")
                    .data([...localdata].reverse().slice(0, -1))
                    .enter()
                    .append("circle")
                    .attr("cx", function (d) { return xScale(d[1]); })
                    .attr("cy", function (d) { return yScale(d[2]); })
                    .attr("r", 5)
                    .style("fill", line_color)
                    .style("stroke", '#333')
                    ;

                if (localdata.length > 1) {
                    g.append('g')
                        .append('text')
                        .attr('x', xScale(localdata[0][1]) + 5)
                        .attr('y', yScale(localdata[0][2]) + 20)
                        .style('fill', "#333")
                        .style('font-size', '90%')
                        .style('font-weight', 'normal')
                        .text(key);
                }

                pos++;
            }

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkgreen")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[xScale(0), 0], [viewportWidth, 0]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkgreen")
                .attr("stroke-width", 30)
                .attr("d", d3.line()([[xScale(0), viewportHeight], [viewportWidth, viewportHeight]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkred")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[0, 0], [xScale(0), 0]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkred")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[0, viewportHeight], [xScale(0), viewportHeight]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkgreen")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[0, yScale(0)], [0, 0]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkgreen")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[viewportWidth, yScale(0)], [viewportWidth, 0]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkred")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[0, yScale(0)], [0, viewportHeight]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "darkred")
                .attr("stroke-width", 30)
                .attr("class", "trace")
                .attr("d", d3.line()([[viewportWidth, yScale(0)], [viewportWidth, viewportHeight]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "#777")
                .attr("stroke-width", 1)
                .attr("class", "trace")
                .attr("d", d3.line()([[xScale(0), 0], [xScale(0), viewportHeight]]));

            svg.append('path')
                .attr("fill", "none")
                .attr("stroke", "#777")
                .attr("stroke-width", 1)
                .attr("class", "trace")
                .attr("d", d3.line()([[0, yScale(0)], [viewportWidth, yScale(0)]]));

            setRedraw(false);

        }

    }

    const updateStartDateString = () => {
        var currentDate = new Date();
        if ((startDateList.length > 0) && (startDateList[0] != null) && (lastDateRef.current) && startDateList[ lastDateRef.current?.value ]) {
            currentDate = startDateList[ lastDateRef.current?.value ];
        }
        setStartDateText(currentDate.toLocaleDateString());
    }

    const showChart = (symbol) => {
        return <TVChart tvSymbol={ symbol }/>
    }

    useEffect( getData, [reload]);
    useEffect( drawPlot, [redraw, currentZoomState]);

    return <>
        <div id="tvGraph" className="modal fade" tabIndex="-1">
            <div className="modal-dialog modal-xl vh-100">
                <div className="modal-content" style={{height: "80%"}}>
                    <div className="modal-header">
                        <h5 className="modal-title">Graph for {tvSymbol}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        { showChart(tvSymbol) }
                    </div>
                </div>
            </div>
        </div>
        <nav className="grid-service navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <div className="navbar-brand">WPI chart</div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarControllerContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarControllerContent">
                    <ul className="navbar-nav d-flex me-auto">
                        <li className="navbar-item pt-2 me-2">
                            <label className="navbar-text me-1 mb-2"><i className="bi-collection" title="Gruppo"></i></label>
                        </li>
                        <li className="navbar-item pt-2 me-2">
                            <select ref={groupRef} className="form-select form-select-sm mb-1 mt-1" onChange={() => {setGroup(encodeURIComponent(groupRef.current?.value)); setReload(true);}} title="Gruppo">
                                { groupsStructure.map( (section) => {
                                    return <optgroup key={section.optgroup} label={section.optgroup}>
                                        {section.groups.map((group) => {
                                            return <option key={group.name} value={group.name}>{group.display}</option>
                                        })}
                                    </optgroup>
                                })}
                            </select>
                        </li>
                        <li className="navbar-item pt-2 me-2">
                            <label className="navbar-text me-1 ms-2"><i className="bi-clock-history" title="Periodo"></i></label>
                        </li>
                        <li className="navbar-item pt-2 me-2">
                            <select ref={typeRef} className="form-select form-select-sm mt-1 md-1" onChange={() => {setType(typeRef.current?.value); setReload(true)}} title="Periodo">
                                <option key="bb" value="bb">Breve</option>
                                <option key="ml" value="ml">Lungo</option>
                            </select>
                        </li>
                        <li className="navbar-item me-1 pt-2"><label className=" navbar-text"><i className="bi-calendar" title="Data fine tracciato"></i></label></li>
                        <li className="navbar-item me-1 pt-3 me-1 fixed-width-9em">
                            <input ref={lastDateRef} type="range" className="form-range w-100" title="Start date" min={0} max={60} step={1} onChange={ () => {updateStartDateString(); setRedraw(true)}}></input>
                        </li>
                        <li className="navbar-text text-center pt-3 me-2 fixed-width-7em">{ startDateText }</li>
                        <li className="navbar-item me-1 pt-2"><label className=" navbar-text"><i className="bi-bezier" title="Lunghezza tracciato"></i></label></li>
                        <li className="navbar-item me-1 pt-3 fixed-width-9em">
                            <input ref={traceLengthRef} type="range" className="form-range w-100" title="Lunghezza tracciato" min={2} max={20} step={1} onChange={() => {setTraceLength(traceLengthRef.current?.value); setRedraw(true)}}></input>
                        </li>
                        <li className="navbar-text text-center me-2 pt-3 fixed-width-3em">{ traceLength }</li>
                        <li className="navbar-item pt-2 me-2">
                            <button className="btn btn-sm btn-success" title="Mostra tutto" onClick={() => showAll()}><i className="bi-eye-fill"></i></button>
                        </li>
                        <li className="navbar-item pt-2 me-2">
                            <button className="btn btn-sm btn-warning" title="Nascondi tutto" onClick={() => hideAll()}><i className="bi-eye-slash-fill"></i></button>
                        </li>
                        <li className="navbar-item me-2 grid-qbuttons">
                            <button className="btn btn-sm p-0 m-0 btn-warning" title="Visualizza solo secondo quadrante" onClick={() => selectQuadran(2)}><strong>2</strong></button>
                            <button className="btn btn-sm p-0 m-0 btn-success" title="Visualizza solo primo quadrante" onClick={() => selectQuadran(1)}><strong>1</strong></button>
                            <button className="btn btn-sm p-0 m-0 btn-danger" title="Visualizza solo terzo quadrante" onClick={() => selectQuadran(3)}><strong>3</strong></button>
                            <button className="btn btn-sm p-0 m-0 btn-warning" title="Visualizza solo quarto quadrante" onClick={() => selectQuadran(4)}><strong>4</strong></button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div className="grid-content grid-dashboard-content">
            <div id="draw-area" className="grid-draw border">
                <div id="tl"></div>
                <div id="tcb"></div>
                <div id="ttcb">
                    <div className="inner horizzontal">
                        <span>&#8592;</span> { xAxisLabel } <span>&#8594;</span>
                    </div>
                </div>
                <div id="tr"></div>
                <div id="lcb"></div>
                <div id="tlcb">
                    <div className="inner vertical">
                        <span>&#8592;</span> {yAxisLabel} <span>&#8594;</span>
                    </div>
                </div>
                <div id="graph">
                    <div id="q1" className="label">UP</div>
                    <div id="q2" className="label">pullback DOWN</div>
                    <div id="q3" className="label">DOWN</div>
                    <div id="q4" className="label">pullback UP</div>
                    <svg ref={graphArea} width="100%" height="100%" id="canvas"/>
                </div>
                <div id="rcb"></div>
                <div id="trcb">
                    <div className="inner vertical">
                        <span>&#8592;</span> {yAxisLabel} <span>&#8594;</span>
                    </div>
                </div>
                <div id="bl"></div>
                <div id="bcb"></div>
                <div id="tbcb">
                    <div className="inner horizzontal">
                        <span>&#8592;</span> {xAxisLabel} <span>&#8594;</span>
                    </div>
                </div>
                <div id="br"></div>
            </div>
            <div className="grid-search p-2 input-group bg-light">
                <div className="input-group-text"><i className="bi-search"></i></div>
                <input type="search" className="form-control border-round-100 ps-2" ref={instrumentSearchBox} onChange={filterInstrument}/>
            </div>
            <div className="reactive-table grid-legend overflow-y-hide">
                <div className="table">
                    <table className="w-100">
                        <tbody>
                            { renderInstrumentsList() }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {/*<script src="https://s3.tradingview.com/tv.js"></script>*/}
    </>
}

export default WPIChart;