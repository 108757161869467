import { Navigate } from "react-router-dom";

const Missing = () => {
    return <div className="grid-container alter alert-warning">
        <h1 className="display-1">Missing</h1>
        <p>The resource you're looking for has not been found.</p>
        <p>Please <Navigate to="/">back</Navigate> to homepage.</p>
    </div>;
};

export default Missing;