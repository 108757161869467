import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    
    const storedToken = localStorage.getItem("ttp_datamanager_token");
    let userStatus = {}
    
    if (storedToken) {
        userStatus = JSON.parse(storedToken);
        
    }

    const [auth, setAuth] = useState(userStatus);

    return <AuthContext.Provider value={{ auth, setAuth }} >
        {children}
    </AuthContext.Provider>
};

export default AuthContext;