import { Group } from '../models';
import {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import { baseUri } from '../commoms';
import { useParams } from 'react-router-dom';

const GroupEdit = (props) => {
    
    let { name } = useParams()
    const [groupName, setGroupName] = useState(name)
    const [group, setGroup] = useState(new Group())
    const [message, setMessage] = useState('')

    const currentSymbolInput = useRef()
    const groupNameInput = useRef()
    const messageToast = useRef()

    const loadGroup = () => {
        let uri = baseUri + '/groups?id=' + encodeURIComponent(groupName);
        console.log("Loading group qith paramenter: ", groupName, " so uri is ", uri);
        axios.get(uri)
            .then(res => { setGroup(res.data[0])})
            .catch(err => { console.error(err)})
    }

    const addSymboldToGruop = () => {

        const s = currentSymbolInput.current.value;
        const g = groupNameInput.current.value;

        axios.put(baseUri + '/groups', { 'name': g, 'symbol': s })
            .then(res => {
                setMessage('Symbol '+ s + ' successfully added to group '+ g + '!')
                messageToast.current.classList.remove('alert-danger')
                messageToast.current.classList.add('alert-success')
            })
            .catch(error => {
                setMessage('Cannot add symbol '+ s + ' to group '+ g + '!')
                messageToast.current.classList.remove('alert-success')
                messageToast.current.classList.add('alert-danger')
            }).finally(() => {
                messageToast.current.classList.add('show')
                loadGroup()
        })

    }

    const updateList = () => {
        console.log("HIT: " + groupNameInput.current.value)
        if (groupNameInput.current.value.length > 3) {
            setGroupName( groupNameInput.current.value )
            // loadGroup()
        }
    }

    const removeSymbolFromGroup = () => {
        const s = currentSymbolInput.current.value;
        const g = groupNameInput.current.value;

        axios.delete(baseUri + '/groups', {data: { 'name': g, 'symbol': s }, headers: {"Accept": "application/json", "Content-type":"application/json"}})
            .then(res => {
                setMessage('Symbol '+ s + ' successfully removed from group '+ g + '!')
                messageToast.current.classList.remove('alert-danger')
                messageToast.current.classList.add('alert-success')
            })
            .catch(error => {
                setMessage('Cannot remove symbol '+ s + ' from group '+ g + ':' + error.message + '!')
                messageToast.current.classList.remove('alert-success')
                messageToast.current.classList.add('alert-danger')
            }).finally(() => {
                messageToast.current.classList.add('show')
                loadGroup()
        })

    }

    useEffect(loadGroup, [groupName]);
    useEffect( ()=> groupNameInput.current.value = groupName);


    return <>
        <div className="grid-service d-flex flex-row justify-content-start mx-2">
            <div className="fw-bold fs-2 d-flex flex-row me-5 mt-2"><i className="bi-collection ml-1"></i> Group</div>
            <div className="input-group my-1 me-auto">
                <input className='form-control' placeholder='Group name' ref={groupNameInput} onKeyUp={updateList}></input>
                <input className='form-control' placeholder='Symbol (ends with ! if benchmark)' ref={currentSymbolInput}></input>
                <button className='btn btn-success' onClick={ addSymboldToGruop }><i className='bi bi-plus-square'></i> Add</button>
                <button className='btn btn-danger' onClick={ removeSymbolFromGroup }><i className='bi bi-dash-square'></i> Remove</button>
            </div>
            <div className='alert alert-dismissable fade d-flex flex-row align-items-center' id="messageToast" ref={ messageToast }>
                <div>{ message }</div>
                <button type="button" className="btn-close m-1" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
        <div className="table-responsive grid-content overflow-y-scroll px-2">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary navbar-dark sticky-top col-1">Symbol</th>
                    </tr>
                </thead>
                <tbody>
                {
                    group.components.length === 0 ? <tr key="none"><td><div className="alert alert-warning text-center">Empty group</div></td></tr> : 
                    group.components.map(element => <tr key={element.symbol} ><td>{element.symbol}</td></tr> )
                }
                </tbody>
            </table>
        </div>
    </>;
}

export default GroupEdit;