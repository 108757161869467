import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUri } from '../commoms';
import { useEffect, useState } from 'react';

const GroupList = (props) =>  {

    const navigate = useNavigate()

    const [ groupsList, setGroupsList ] = useState([]);

    const editGroup = (groupName) => {

        if (groupName == null) {
            navigate('group-edit')
        } else {
            navigate('group-edit/' + groupName)
        }
    }

    const listGroup = () => {
        console.log("getting list groups");
        axios.get(baseUri + "/groups", { headers: { "Accept": "application/json" } })
            .then((res) => {
                console.log("Data read!")
                console.log(res);
                setGroupsList(res.data);
            })
            .catch((err) => { console.log(err) });
    }

    useEffect(() => listGroup(), []);


    return <>
        <div className="bg-light grid-service d-flex flex-row justify-content-spacebetween py-2 px-2 ">
            <div className="fw-bold fs-2 d-flex flex-row align-content-center me-2">
                <i className="bi-collection me-1"></i> Groups
            </div>
            <button onClick={() => editGroup(null)} className="btn btn-sm btn-primary my-2"><i className="bi-plus-circle"></i> Add group</button>
        </div>
        <div className="grid-content overflow-y-scroll table-responsive px-2">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary navbar-dark sticky-top col-10">Name</th>
                        <th className="table-primary navbar-dark sticky-top col-1 text-center">Instruments</th>
                        <th className="table-primary navbar-dark sticky-top col-1 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { groupsList.length === 0 ? <tr key="0" ><td colSpan="3" className="bg-warning p-4 fs-2 fw-bold">No groups yet</td></tr> : 
                        groupsList.map(group => <tr key={group.id}>
                            <td>{group.name}</td>
                            <td className="text-center">{group.count}</td>
                            <td className="text-right"><Link to={"/group-edit/"+ encodeURIComponent(group.name)} className="btn btn-sm btn-primary"><i className="bi-pencil"></i> Edit</Link></td>
                    </tr>)
                    }
                </tbody>
            </table>
        </div>
    </>;

}

export default GroupList;