import { useEffect, useRef, useState } from "react";
import { baseUri } from "../commoms";
import axios from "axios";
import Plot from "react-plotly.js";
import GlobalAssetAllocation from "../components/GlobalAssetAllocation";
import DataTables from "../components/DataTables";
import DataTablesCalendar from "../components/DataTablesCalendar";

const Dashboard = () => {
    const [htmlwidgetData, setHtmlwidgetData] = useState([]);
    const [plotData, setPlotData] = useState({});
    const [viewPlot, setViewPlot] = useState(false);
    const [lastUpdate, setLastUpdate] = useState("");
    const [description, setDescription] = useState(<></>);

    const [sortCommands, setSortCommands] = useState([]);
    const [sortedData, setSortedData] = useState([]);

    const userStatus = JSON.parse(localStorage.getItem("dmuser"));

    const [tabs, setTabs] = useState([
        {
            title: "Global asset allocation",
            label:"GlobalAssetAllocation",
            page:"GLOBAL_ASSETALLOCATION.html",
            active: false,
            role: "*",
            description: null,
            id: 1
        },
        {
            title: "Today's signals",
            label:"SegnaliOdierni",
            page:"SegnaliOdierni.html",
            active: false,
            role: "*",
            description: <>
                Nella Sezione <strong>Today's Signals</strong> sono contenuti i segnali entrati a mercato/rotto la lateralità Wyckoff
                nel giorno trading precedente. Le operazioni prima di essere indicate in questa sezione vengono indicate nella sezione
                Pending Signals quando il prezzo è vicino al livello di apertura. Attenzione tutte le indicazioni vengono fornite a
                mercato chiuso con dati EOD.
                <strong>Data chiusura:</strong> quando la data di chiusura è assente significa che il segnale non è ancora entrato a
                mercato o che la lateralità non è ancora stata rotta.
                <strong>Risultato:</strong> se la casella risulatato è vuota significa che il segnale è ancora aperto e non ha
                raggiunto né il target T1 né lo stop loss.
            </>,
            id: 200
        },
        {
            title: "Pending signals",
            label:"DashboardPendenti",
            page:"DashboardPendenti.html",
            active: false,
            role: "*",
            description:<>
                Nella sezione <strong>Pending Signals</strong> sono contenute le operazioni il cui prezzo si sta avvicinando al livello di apertura
                ovvero il livello di rottura della laterlaità Wyckoff. Una volta che il livello di apertura è toccato le operazioni
                vengono toltre da questa sezione e vengono inserite nella sezione Today's Signals come operazioni entrate a mercato
                nel giorno trading Precedente. Attenzione tutte le indicazioni vengono foprnite a mercato chiuso con dati EOD.
                <strong>Data Apertura:</strong> nelle operazioni pendenti la data di apertura è 0 poiché l'operazione non risulta
                ancora a mercato o la lateralità non risulta ancora rotta.
            </>,
            id: 300
        },
        {
            title: "Selected USA Data",
            label:"SelezioneUsaQ1",
            page:"SelezioneUsaQ1.html",
            active: false,
            role: 'ROLE_SP500',
            description:<>
                La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
                quali viene analizzato :
                <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
                strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
                rappresenta lo strumneto più forte e 500 lo strumento più debole.
                <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
                degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
                basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
                <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
                attraberso il Wyckoff Position Index Chart a 4 quadranti.
            </>,
            id: 2
        },
        {
            title: "Selected USA Signals",
            label:"SelezioneUsaQ1",
            page:"SelezioneUsaQ1_Calendario.html",
            active: false,
            role: 'ROLE_SP500',
            description:<>
                Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
                Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
                è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
            </>,
            id: 102
        },
        {
            title: "Selected Standard & Poor's (100) Data",
            label:"SelezioneSP100",
            page:"SelezioneSP100.html",
            active: false,
            role: 'ROLE_SP100',
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 3
        },
        {
            title: "Selected Standard & Poor's (100) Signals",
            label:"SelezioneSP100",
            page:"SelezioneSP100_Calendario.html",
            active: false,
            role: 'ROLE_SP100',
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 103
        },
        {
            title: "Selected NASDAQ (100) Data",
            label:"NASDAQ100",
            page:"NASDAQ100.html",
            active: false,
            role: 'ROLE_NA100',
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 4
        },
        {
            title: "Selected NASDAQ (100) Signals",
            label:"NASDAQ100",
            page:"NASDAQ100_Calendario.html",
            active: false,
            role: 'ROLE_NA100',
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 104
        },
        {
            title: "Selected FOREX (Q1) Data",
            label:"SelezioneForexQ1",
            page:"SelezioneForexQ1.html",
            active: false,
            role: 'ROLE_FOREX',
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 5
        },
        {
            title: "Selected FOREX (Q1) Signals",
            label:"SelezioneForexQ1",
            page:"SelezioneForexQ1_Calendario.html",
            active: false,
            role: 'ROLE_FOREX',
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 105
        },
        {
            title: "Selected CRYPTO (Q1) Data",
            label:"SelezioneCriptoQ1",
            page:"SelezioneCriptoQ1.html",
            active: false,
            role: "ROLE_CRYPT",
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 6
        },
        {
            title: "Selected CRYPTO (Q1) Signals",
            label:"SelezioneCriptoQ1",
            page:"SelezioneCriptoQ1_Calendario.html",
            active: false,
            role: "ROLE_CRYPT",
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 106
        },
        {
            title: "Selected ITALY (Q1) Data",
            label:"SelezioneItaliaQ1",
            page:"SelezioneItaliaQ1.html",
            active: false,
            role: "ROLE_ITALY",
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 7
        },
        {
            title: "Selected ITALY (Q1) Signals",
            label:"SelezioneItaliaQ1",
            page:"SelezioneItaliaQ1_Calendario.html",
            active: false,
            role: "ROLE_ITALY",
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 107
        },
        {
            title: "Selected FTSEMIB (Q1) Data",
            label:"SelezioneFTSEMIBQ1",
            page:"SelezioneFTSEMIBQ1.html",
            active: false,
            role: "ROLE_FTSEM",
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 8
        },
        {
            title: "Selected FTSEMIB (Q1) Signals",
            label:"SelezioneFTSEMIBQ1",
            page:"SelezioneFTSEMIBQ1_Calendario.html",
            active: false,
            role: "ROLE_FTSEM",
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 108
        },
        {
            title: "Selected INDEX (Q1) Data",
            label:"SelezioneIndiciQ1",
            page:"SelezioneIndiciQ1.html",
            active: false,
            role: "ROLE_INDEX",
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 9
        },
        {
            title: "Selected INDEX (Q1) Signals",
            label:"SelezioneIndiciQ1",
            page:"SelezioneIndiciQ1_Calendario.html",
            active: false,
            role: "ROLE_INDEX",
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>                                                                                                       ,
            id: 109
        },
        {
            title: "Selected Commodities (Q1) Data",
            label:"SelezioneCommoditiesQ1",
            page:"SelezioneCommoditiesQ1.html",
            active: false,
            role: "ROLE_COMMO",
            description:<>
            La sezione Data è la sezione Analisi del sistema. Sono contenuti tutti gli strumenti della categoria scelta dei
            quali viene analizzato :
            <strong>Forza Relativa:</strong> è la classifica della forza relativa dello strumento rispetto a tutti gli
            strumenti dell'indice al quale appartiuene. Se fosse lo S&P500, la forza relativa va da 1 a 500, dove 1
            rappresenta lo strumneto più forte e 500 lo strumento più debole.
            <strong>Forza Relativa sul settore:</strong> è classifica della forza relativa dello strumento sull'insieme
            degli strumenti apparteneneti allo stesso settore. Il settore viene scritto nella prima  colonna, più è
            basso il numero più lo strumento è fortse più è alto il numero più lo strumento è debole.
            <strong>Posizione Lunga</strong> & <strong>Posizione Breve</strong>: rappresenta la forza dello strumento
            attraberso il Wyckoff Position Index Chart a 4 quadranti.
        </>,
            id: 10
        },
        {
            title: "Selected Commodities (Q1) Signals",
            label:"SelezioneCommoditiesQ1",
            page:"SelezioneCommoditiesQ1_Calendario.html",
            active: false,
            role: "ROLE_COMMO",
            description:<>
            Rappresenta la sezione storica di tutti i segnali/lateralità individuate nell'ultimo anno di trading.
            Se la <strong>data chiusura</strong> & <strong>risultato</strong> sono vuoti significa dire che l'operazione
            è ancora aperta e che non è stato ancora toccato né il <strong>target</strong> né lo <strong>stop loss</strong>.
        </>,
            id: 110
        },
    ]);


    const [selectedIndex, setSelectedIndex] = useState(1);

    const plotContainer = useRef();

    const loadPage = (event, pos, pagename) => {

        console.log("Loading page ", pagename);
        if (event)
            event.preventDefault();
        setSelectedIndex(pos);
        setHtmlwidgetData( [] );

        axios.get(baseUri + `/html?filename=${pagename}`, { headers: { "Accept": "application/json" } })
            .then( res => {
                let fullPage = res.data.content.join("\n");
                const parser = new DOMParser();
                let xmlPage = parser.parseFromString(fullPage, "text/html");
                const scripts = xmlPage.querySelectorAll("script");
                for( let k =0; k < scripts.length; k++ ) {
                    let script = scripts[k];
                    if (script.getAttribute("data-for")
                        && script.getAttribute("data-for").includes("htmlwidget")
                        && script.getAttribute("type").includes("json")) {
                            const data = JSON.parse(script.innerText);
                            if ( data.x) {
                                // setHtmlwidgetData( { ...htmlwidgetData, ...data.x.data} );
                                setHtmlwidgetData( [...data.x.data] );
                                setSortedData( [...data.x.data]);
                                const timestamp = parseInt(res.data.creationDate);
                                try {
                                    const date = new Date(timestamp * 1000);
                                    setLastUpdate(date.toISOString().split('T')[0]);
                                    const st = tabs.filter( e => e.id === pos)[0];
                                    setDescription(st['description']);
                                } catch (e) {
                                    setLastUpdate("(NO INFO)");
                                }
                            }         
                    }
                }

            })
            .catch( err => {
                console.error(err);
            });
        
        let _tabs = tabs.map( (tab, index) => {
            if (pos === tab.id)
                return { title: tab.title, page:tab.page, active: true, role: tab.role, id: tab.id, label: tab.label, description: tab.description};
            else
                return { title: tab.title, page:tab.page, active: false, role: tab.role, id: tab.id, label: tab.label, description: tab.description};
        });

        setTabs(_tabs);

    }

    

    const loadGraph = (plotName) => {

        if (!plotName.includes(".html")) {
            plotName += ".html";
        }

        axios.get(baseUri + `/html?filename=${plotName}`, { headers: { "Accept": "application/json" } })
            .then( res => {
                let fullPage = res.data.content.join("\n");
                const parser = new DOMParser();
                let xmlPage = parser.parseFromString(fullPage, "text/html");
                const scripts = xmlPage.querySelectorAll("script");
                for( let k =0; k < scripts.length; k++ ) {
                    let script = scripts[k];
                    if (script.getAttribute("data-for")
                        && script.getAttribute("data-for").includes("htmlwidget")
                        && script.getAttribute("type").includes("json")) {
                            const data = JSON.parse(script.innerText);
                            setPlotData( { ...plotData, ...data} );
                            setViewPlot(true);
                    }
                }

            })
            .catch( err => {
                console.err(err);
            });


    };

    const sortData = () => {
        console.log("START SORTING!");
        if (sortCommands.length > 1) {
            console.log("Sorting rule for ", sortCommands);
            const column = sortCommands[0] +1;
            const direction = sortCommands[1];
            let localData = [...htmlwidgetData];
            let localSortedData = traspose(traspose(localData).sort( (a,b) => {
                if ( a[column] === b[column])
                    return 0;
                else if ( a[column] > b[column])
                    return direction;

                return -direction;
            }));
            setSortedData([...localSortedData]);
            console.log(sortedData);
        } else {
            console.log("No sorting rule!");

            setSortedData([...htmlwidgetData]);
        }
    };

    const doSort = (sortArray) => {
        setSortCommands(sortArray);
    };

    const traspose = matrix => {if (matrix[0]) return matrix[0].map((x,i) => matrix.map(x => x[i])); else return matrix;};



    useEffect(()=>loadPage(null, 1, "GLOBAL_ASSETALLOCATION.html"),[]);

    useEffect(()=> sortData(), [sortCommands]);

    return <>
        <div className="grid-service d-flex flex-row align-items-center p-1">
            <div className="dropdown me-auto">
                <button className="btn btn-primary fs-4 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="me-2">{tabs.filter( (element) => element.id === selectedIndex )[0].title}</span>
                </button>
                <ul className="dropdown-menu z-index-modal scrollable-menu">
                {
                    tabs.filter( (t) => { return (t.role==="*") || (userStatus.isAdministrator) || (userStatus.roles.includes(t.role)) }).map ( (tab, index) => {
                        return <li className="nav-item" key={tab.page}>
                            <a className={ "nav-link" + ( tab.active ? " active" : "")} href="." onClick={ (e) => loadPage(e, tab.id, tab.page)}>{tab.title}</a>
                        </li>
                    })
                }
                </ul>
            </div>
            {
                description === null
                ? ""
                : <div className="alert alert-info mx-2 p70 p-1 mt-2"><i className="bi-info-circle me-1"></i>{description}</div>
            }
            <div className="p70"><i className="bi-calendar me-1"></i><span className="me-1"><strong>Last update</strong></span><span>{lastUpdate}</span></div>
            {
                viewPlot ? <div className="border border-secondary border-top p-2"><button className="btn btn-sm btn-indo" onClick={ () => setViewPlot(false)}>Close plot</button></div>
                : ""
            }
       </div>
       <div className="grid-row-start-3 grid-row-end-4 grid-column-start-1 grid-column-end-3 table-responsive">
        {
            selectedIndex === 1 ? <GlobalAssetAllocation data={htmlwidgetData} /> : (
                selectedIndex < 100 ? <DataTables data={sortedData} type={selectedIndex} loadGraph={loadGraph} doSort={doSort} sortCommands={sortCommands}/> : <DataTablesCalendar data={htmlwidgetData} type={selectedIndex} tabs = {tabs} loadGraph={loadGraph}/>
            )
        }
       </div>
       {
            viewPlot ? <div ref={plotContainer} className="z-index-modal grid-row-start-3 grid-row-end-4 grid-column-start-1 grid-column-end-3 d-flex flex-column bg-light w-100 h-100 p-2">
                {plotData.x ? <Plot data= {plotData.x.data} layout = {{...plotData.x.layout, width: window.innerWidth -10, height: window.innerHeight - 210}}/> :
                    <div className="alert alert-warning">NO PLOT</div>}
            </div> : ""
       }
    </>
}

export default Dashboard;