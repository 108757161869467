import "../App.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUri } from '../commoms';

function SchedulesList() {
    const [schedulesList, setSchedulesList] = useState([])

    const loadSchedules = () => {
        axios.get(baseUri + "/schedules", { headers: {"Accept": "application/json"}})
            .then(res => {
                console.log(res);
                setSchedulesList(res.data);
            })
            .catch(exp => {
                console.log(exp);
            });
    }

    const deleteSchedule = (id) => {
        axios.delete(baseUri + "/schedules?id="+id, { headers: {"Accept": "application/json"}})
            .then(res => {
                console.log(res);
                loadSchedules();
            })
            .catch(exp => {
                console.log(exp);
                loadSchedules();
            });
    }

    const runSchedule = (id) => {
        axios.get(baseUri + "/schedules?id="+id+'&action=run', { headers: {"Accept": "application/json"}})
            .then(res => {
                console.log(res);
                loadSchedules();
            })
            .catch(exp => {
                console.log(exp);
                loadSchedules();
            });
    }

    useEffect(() => { loadSchedules() }, [])

    return <>
        <div className="bg-light grid-service d-flex flex-row py-2 justify-content-between px-2">
            <div className="fw-bold fs-2 d-flex flex-row me-5">
                <i className="bi-clock-history me-1"></i> Schedules
                <Link  to="/add-schedule" role="button" className="btn btn-primary btm-sm align-self-middle my-2 ms-2" ><i className="bi-plus-circle"></i> Add schedule</Link>
            </div>
            
        </div>
        <div className="table-responsive px-2 grid-content">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary navbar-dark sticky-top col-2 text-center">Actions</th>
                        <th className="table-primary navbar-dark sticky-top col-2">Name</th>
                        <th className="table-primary navbar-dark sticky-top col-1 text-center">Internal</th>
                        <th className="table-primary navbar-dark sticky-top col-4">Parameters</th>
                        <th className="table-primary navbar-dark sticky-top col-3">Schedulation</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        schedulesList.map((schedule) => 
                            <tr key={ schedule.id }>
                                <td className="text-center">
                                    <button className="btn btn-sm btn-danger" onClick={() => { deleteSchedule(schedule.id) }}><i className="bi-trash"></i> delete</button>
                                    <button className="btn btn-sm btn-warning ms-1" onClick={() => { runSchedule(schedule.id) }}><i className="bi-play"></i> run now</button>
                                </td>
                                <td>{schedule.scriptCode}<br/>({schedule.lastRun == null ?"Never executed":`Last execution on ${schedule.lastRun}`})</td>
                                <td className="text-center">{schedule.internalJob ? <i className="bi-check-square"></i> : <i className="bi-square"></i>}</td>
                                <td>{schedule.params }</td>
                                <td>
                                    <span className="badge bg-primary mr-2">{schedule.second}<sup>s</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.minute}<sup>m</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.hour}<sup>h</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.week}<sup>w</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.dayOfWeek}<sup>dow</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.date}<sup>D</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.month}<sup>M</sup></span>&nbsp;
                                    <span className="badge bg-primary mr-2">{schedule.year}<sup>Y</sup></span>&nbsp;
                                </td>
                            </tr>
                        )
                    }
                </tbody>
                
            </table>
    </div>

    </>;
}

export default SchedulesList;