import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUri } from "../commoms";

const ScriptsList = (props) => {

    const [scriptsList, setScriptsList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const getScripts = () => {
        console.log("Getting scripts...")
        setLoadingStatus(true);
        axios.get(baseUri + "/scripts", { headers: { "Accept": "application/json" } })
            .then(res => {
                setScriptsList(res.data.data);
                setLoadingStatus(false);
            })
            .catch(exp => {
                setLoadingStatus(false);
                console.log(exp);
            });
    };

    useEffect(() => {
        getScripts();
    }, []);

    const runScript = (script) => {

        axios.get(baseUri + "/scripts?code=" + script.code + "&action=run", { headers: { "Accept": "application/json" } })
            .then(res => {
                alert(res.data.message);
                })
            .catch(exp => {
                alert(exp);
                console.log(exp);
            });

    }

    const deleteScript = (script) => {
        axios.delete(baseUri + "/scripts?code=" + script.code, { headers: { "Accept": "application/json" } })
            .then(res => {
                alert(res.data.message);
            })
            .catch(exp => {
                alert(exp);
                console.log(exp);
            })
            .finally( () => {
                getScripts();
            });

    }

    return <>
        <div className="grid-service bg-light d-flex flex-row justify-content-spacebetween py-2 px-2 ">
            <div className="fw-bold fs-2 d-flex flex-row align-content-start me-2">
                <i className="bi-journal-code me-1"></i> R scripts {loadingStatus ? <span className="alert alert-warning"><i className="bi-downlad"></i>&nbsp; loadig</span> : ""}
            </div>
            <Link role="button" to="/add-script" className="btn btn-sm btn-primary my-2"><i className="bi-plus-circle"/> Add script</Link>
        </div>
        <div className="grid-content table-responsive px-2 overflow-y-scroll">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary col-2">Code</th>
                        <th className="table-primary col-8">Name</th>
                        <th className="table-primary text-end col-3">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {scriptsList.map( (script, index) => <tr key={ index }>
                        <td>{script.code}</td>
                        <td>{script.name}</td>
                        <td className="text-end">
                            <Link role="button" to={"add-script?code=" + script.code} className="btn btn-sm btn-warning"><i className="bi-pencil"></i> Edit</Link>
                            <button className="btn btn-sm btn-danger mx-2" onClick={ () => deleteScript(script)}><i className="bi-trash"></i> Delete</button>
                            <button className="btn btn-sm btn-info" onClick={ () => runScript(script)}><i className="bi-play"></i> Run</button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </>;

}

export default ScriptsList;