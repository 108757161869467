import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUri } from "../commoms";
import { StockInfo } from '../models';

function DataList() {

    let { symbol } = useParams()

    let [datasList, setDatasList] = useState([]);
    let [loadingStatus, setLoadingStatus] = useState(false);
    let [stockInfo, setStockInfo] = useState(new StockInfo());

    const getInfo = () => {
        setLoadingStatus(true);
        axios.get(baseUri + "/stocks?symbol=" + symbol, { headers: { "Accept": "application/json" } })
            .then(res => {
                console.log(res.data);
                setStockInfo(res.data.data[0]);
                setLoadingStatus(false);
            })
            .catch(exp => {
                setLoadingStatus(false);
                console.log(exp);
            }
        );
        getDatas();

    };

    const getDatas = () => {
        setLoadingStatus(true);
        axios.get(baseUri + "/datas?symbols=" + symbol + "&x=Brevissimo periodo&y=Breve periodo&limit=80", { headers: { "Accept": "application/json" } })
            .then(res => {
                const ld = res.data.datas[symbol].data;
                ld.reverse();
                setDatasList([...ld]);
                setLoadingStatus(false);
            })
            .catch(exp => {
                setLoadingStatus(false);
                console.log(exp);
            });
    }

    useEffect(() => {
        getInfo();
    }, []);

    return <>
        <div className="bg-light grid-service d-flex flex-row py-2 justify-content-start px-2">
            <div className="fw-bold fs-2 d-flex flex-row me-5">
                History of &nbsp;<span className="display-6">{stockInfo.description}</span> <sup className="badge rounded-pill bg-secondary fs-6 h-50 mt-1">{stockInfo.quoteType}</sup> &nbsp;
                {
                    loadingStatus ?
                        <span className="badge bg-warning">
                            <i className="bi-cloud-download"></i> loading data ...
                        </span> :
                        ""
                }
                <div className="ms-2 btn-group">
                    <button className="btn btn-outline-info dropdown-toggle" type="button" data-bs-toggle="dropdown">
                        INFO
                    </button>
                    <div className="dropdown-menu w-30rem px-2 z-index-front">
                        <div className="dropdown-text row">
                            <div className="col display-6">{stockInfo.description}</div>
                        </div>
                        <div className="dropdown-divider">
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Symbol</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.symbol}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Industry</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.industry}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Sector</strong></div>
                            <div className="col text-end text-secondary">{ stockInfo.sector}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Dividend Rate</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.dividendRate ? stockInfo.dividendRate.toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : "--"} {stockInfo.currency}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Ex Dividend Rate</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.exDividendRate ? stockInfo.exDividendRate.toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : "--"}  {stockInfo.currency}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Market cap</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.marketCap ? stockInfo.marketCap.toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : "--"} {stockInfo.currency}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Dividend Yield</strong></div>
                            <div className="col text-end text-secondary">{ stockInfo.dividendYield ? stockInfo.dividendYield.toLocaleString(undefined, {maximumFractionDigits: 4, minimumFractionDigits: 4}) : "--"} {stockInfo.currency}</div>
                        </div>
                        <div className="dropdown-divider">
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Quotes</strong></div>
                            <div className="col text-end text-secondary">{stockInfo.historySize}</div>
                        </div>
                        <div className="dropdown-text row">
                            <div className="col"><strong>Last updated</strong></div>
                            <div className="col text-end text-secondary">{ stockInfo.lastUpdate ? stockInfo.lastUpdate.substring(0,10) : ""}</div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav my-2">
                <li className="nav-item">
                    <Link className="nav-link active" to={"/prices/" + stockInfo.symbol}>Dailies</Link>
                </li>
                <li className="nav-item border rounded-5 alert-warning">
                    <Link className="nav-link" to={"/data-view-bb/" + stockInfo.symbol}>Short term</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={"/data-view-ml/" + stockInfo.symbol}>Long term</Link>
                </li>
            </ul>
        </div>
        <div className="grid-content table-responsive overflow-y-scroll px-2">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary col-2 sticky-top">Date</th>
                        <th className="table-primary text-end col-5 sticky-top">Very short</th>
                        <th className="table-primary text-end col-5 sticky-top">Short</th>
                    </tr>
                </thead>
                <tbody>
                    {datasList.map((datas, index) => <tr key={index}>
                        <td>{datas[0]}</td>
                        <td className="text-end">{ datas[1].toLocaleString(undefined, {maximumFractionDigits:4, minimumFractionDigits:4})}</td>
                        <td className="text-end">{datas[2].toLocaleString(undefined, {maximumFractionDigits:4, minimumFractionDigits:4})}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </>
}

export default DataList;