import { findRenderedDOMComponentWithClass } from "react-dom/cjs/react-dom-test-utils.production.min";

export class Schedule {
    scriptCode;
    year;
    month;
    dayOfWeek;
    week;
    date;
    hour;
    minute;
    second;
    internalJob;
    params;

    constructor( 
        scriptCode = '',
        year = '*',
        month = '*',
        dayOfWeek = '*',
        week = '*',
        date = '*',
        hour = '*',
        minute = '*',
        second = '*',
        internalJob = false,
        params = ""
    ) {
        this.scriptCode = scriptCode;
        this.year = year;
        this.month = month;
        this.dayOfWeek = dayOfWeek;
        this.week = week;
        this.date = date;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.internalJob = internalJob;
        this.params = params;
    }
}

export class StockInfo {
    symbol;
    description;
    industry;
    sector;
    quoteType;
    dividendRate;
    exDividendRate;
    currency;
    marketCap;
    dividendYield;
    historySize;
    lastUpdate;

    constructor(
        symbol = '',
        description = '', 
        industry = '',
        sector = '',
        quoteType = '',
        dividendRate = 0.0, 
        exDividendRate = 0.0,
        currency = '',
        marketCap = 0.0,
        dividendYield = 0.0,
        historySize = 0,
        lastUpdate = null
    ) {
        this.symbol = symbol;
        this.description = description;
        this.industry = industry;
        this.sector = sector;
        this.quoteType = quoteType;
        this.dividendRate = dividendRate;
        this.exDividendRate = exDividendRate;
        this.currency = currency;
        this.marketCap = marketCap;
        this.dividendYield = dividendYield;
        this.historySize = historySize;
        this.lastUpdate = lastUpdate;
    }
}

export class Script {
    code;
    name;
    description;
    source;
    args;
    constructor(_code='',
                _name='',
                _description='',
                _source='',
                _args='') {
        this.code = _code;
        this.name = _name;
        this.description = _description;
        this.source = _source;
        this.args = _args;
    }
}

export class Group {
    name;
    component;
    constructor(name = '', components = []) {
        this.name = name;
        this.components = components;
    }
}

export class Hierarchy {
    name;
    parent;
    constructor(name = '', parent = '') {
        this.name = name;
        this.parent = parent;
    }
}