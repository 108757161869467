import axios from 'axios';
import { useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUri } from '../commoms';
import useAuth from '../hooks/useAuth';
import logo from '../assets/logo.png';

const Login = (props) => {

    const username = useRef();
    const password = useRef();
    const loginButton = useRef();
    const resetButton = useRef();
    const navigate = useNavigate();
    const { setAuth } = useAuth();

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess]  = useState(false);
    const [showWorking, setShowWorking] = useState(false);

    const doLogin = () => {
        loginButton.current.disabled = true;
        resetButton.current.disabled = true;
        setShowWorking(true);
        setShowError(false);
        axios.post(
            baseUri + '/login',
            { "username": username.current.value, "password": password.current.value },
            { headers: { "Accept": "application/json" } })
            .then(response => {

                const userStatus = {
                    "isAuthenticated": "true",
                    "username": response.data.username,
                    "token": response.data.token,
                    "isAdministrator": response.data.isAdministrator,
                    "roles": response.data.roles
                };

                localStorage.setItem('dmuser', JSON.stringify(userStatus));

                setShowWorking(false);
                setShowSuccess(true);
                setAuth(userStatus);

                navigate('/');
            })
            .catch(
                error => {
                    setShowWorking(false);
                    setShowError(true);
                    loginButton.current.disabled = false;
                    resetButton.current.disabled = false;
                    username.current.focus();

                }
            );
    }

    const doReset = () => {
        username.current.value = "";
        password.current.value = "";
    }

    const doSubmit = (event) => {
        event.preventDefault();
        doLogin();
    }

    const areButtonsToEnable = () => {
        const enable = (password.current.value.length > 0) && (username.current.value.length > 0);
        loginButton.current.disabled = !enable;
        resetButton.current.disabled = !enable;
    }

    return <div className="grid-content d-flex flex-column justify-content-center align-items-center">
        <div className="card col-10 col-lg-6">
            <div className="card-body">
                <h5 className="card-title display-6"><i className="bi-shield-lock me-1"></i>Wyckoff Position Index <sub>authentication</sub></h5>
                <h6 className="card-subtitle mb-5 text-muted">Please login using your Trading campus's credentials</h6>
                <form onSubmit={ doSubmit }>
                    <p className="mb-2">
                        <input type="text" className="form-control" placeholder="username" ref={username} onChange={areButtonsToEnable}/>
                    </p>
                    <p className="mb-5">
                        <input type="password" className="form-control" placeholder="password" ref={password} onChange={areButtonsToEnable}/>
                    </p>
                    {showError ? <div className="alert alert-danger text-center mb-5">Unable to login, please check username and password.</div> : ""}
                    {showSuccess ? <div className="alert alert-success text-center mb-5">Authenticatio succeded, redirecting to main page.</div> : ""}
                    {showWorking ? <div className="alert alert-info text-center mb-5 d-flex flex-row justify-content-center align-items-center">
                        <div className="spinner-border me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div>Authentication and authorizations request sent, <strong>PLEASE WAIT!</strong>.</div></div> : ""}
                    <div className="mb-2 d-flex flex-row">
                        <div className="me-auto">
                            <button className="btn btn-success me-2" onClick={doSubmit} ref={loginButton}><i className="bi-box-arrow-in-right me-2"></i> Login</button>
                            <button className="btn btn-warning" onClick={doReset} ref={resetButton}><i className="bi-x me-2"></i>Reset</button>
                        </div>
                        <div>
                            <img src={logo} alt="The Trading Playground - Wyckoff Position Index" className="half-image" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
};

export default Login;