import "../App.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { baseUri } from '../commoms';
import { AdvancedRealTimeChart } from 'react-tradingview-widget-components';

function StocksList() {
    const [stocksList, setStocksList] = useState([]);
    const [readingStocks, setReadingStocks] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize, setPageSize] = useState(250);
    const pageSize = 250;
    const [pageCount, setPageCount] = useState(0);
    const [waitingMessage, setWaitingMessage] = useState("Loading ...");
    const modalSymbol = useRef();
    const [viewSymbol, setViewSymbol] = useState("");

    let readStocks = (page) => {
        let symbolInput = document.getElementById('symbol');
        let symbol = "";
        let groups = "";
        if (symbolInput.value.includes('groups:')) {
            groups = symbolInput.value.split(':')[1];
        } else {
            symbol = symbolInput.value.toUpperCase();
        }
                    
        let path = "?";
        if (symbol) {
            path += "symbol=" + symbol + "&"; 
        }
        if (groups) {
            path += "groups=" + encodeURIComponent(groups) + "&";
        }
        path += "page=" + page;


        setReadingStocks(true);
        setWaitingMessage("Loading data ...")
        axios.get(baseUri + "/stocks"+path, { headers: {"Accept": "application/json"}})
            .then(res => {
                setStocksList(res.data.data);
                setCurrentPage( res.data.page);
                setPageCount( res.data.total)
                setReadingStocks(false);
            })
            .catch(exp => {
                console.log(exp);
                setWaitingMessage(exp);
                setReadingStocks(false);
            });
    }

    let addStock = () => {
        let symbolInput = document.getElementById('symbol');
        let symbol = symbolInput.value.toUpperCase();
        symbolInput.value = "";
        setReadingStocks(true);
        setWaitingMessage("Sending data ...")
        axios.put(baseUri + "/stocks", { "symbol": symbol }, { headers: { "Accept": "application/json" } })
            .then(res => {
                readStocks(1);
            })
            .catch(err => {
                console.log(err);
        })
    }

    let removeStock = () => {
        let symbolInput = document.getElementById('symbol');
        let symbol = symbolInput.value.toUpperCase();
        symbolInput.value = "";
        setReadingStocks(true);
        setWaitingMessage("Sending data ...")
        axios.delete(baseUri + "/stocks", { "symbol": symbol }, { headers: { "Accept": "application/json" } })
            .then(res => {
                readStocks(1);
            })
            .catch(err => {
                console.log(err);
        })
    }

    let forceFullDownload = (symbol, period="300") => {
        setReadingStocks(true);
        setWaitingMessage("Updating data for " + symbol + " ...");
        axios.post(baseUri + "/stocks", { "symbol": symbol, "period": period, "overwrite": "true" }, { headers: { "Accept": "application/json" } })
            .then(res => {
                readStocks(1);
            })
            .catch(err => {
                console.log(err);
                readStocks(1);
        })
    }

    let firstPage = () => {
        console.log("Go to first page");
        setCurrentPage(1);
        readStocks(1);
    }
    
    let prevPage = () => {
        console.log("Got to previous page ");
        console.log("from " + currentPage + " to " + (currentPage-1))
        if (currentPage > 0) {
            const nextPage=currentPage -1;
            setCurrentPage( (prev) => nextPage);
            readStocks(nextPage);
        }
    }
    
    let nextPage = () => {
        console.log("Go to next page")
        console.log( "from " + currentPage + " to " + (currentPage+1))
        if (currentPage < pageCount) {
            const nextPage = currentPage +1
            setCurrentPage((prev) => nextPage);
            readStocks(nextPage);
        }
    }

    let lastPage = () => {
        console.log("Go to last page");
        setCurrentPage( pageCount );
        readStocks(pageCount);
    }

    let showDailyGraph = (tvSymbol) => {
        
        setViewSymbol(tvSymbol);

    }

    useEffect(() => {
        readStocks(1)
    }, []);

    return <>
        <div className="bg-light grid-service d-flex flex-row py-2 justify-content-between px-2">
            <div className="fw-bold fs-2 d-flex flex-row me-5"><i className="bi-graph-up me-1"></i> Instruments {readingStocks ? <div className="badge bg-warning"><i className="bi-cloud-download"></i> {waitingMessage}</div> : ""}</div>
            <div className="input-group my-1">
                <button className="btn btn-primary" onClick={ firstPage } title="go to first page"><i className="bi-skip-start"></i></button>
                <button className="btn btn-primary" onClick={ prevPage } disabled={ currentPage === 1} title="go to previuos page"><i className="bi-rewind"></i></button>
                <div className="input-group-text">Page {currentPage} of  {pageCount} (page size: {pageSize})</div>
                <button className="btn btn-primary" onClick={ nextPage } disabled={ currentPage === pageCount} title="go to next page"><i className="bi-fast-forward"></i></button>
                <button className="btn btn-primary" onClick={ lastPage } title="go to last page"><i className="bi-skip-end"></i></button>
            </div>
            <div className="input-group my-1">
                <span className="input-group-text" id="basic-addon1"><i className="bi-tag"></i> Symbol</span>
                <input type="text" className="form-control" placeholder="symbol" aria-label="symbol" aria-describedby="basic-addon1" id="symbol"/>
                <button className="btn btn-info" type="button" id="button-search" onClick={ () => readStocks(1) }><i className="bi-search"></i> Search</button>
                <button className="btn btn-success" type="button" id="button-add" onClick={ addStock }><i className="bi-plus-circle"></i> Add</button>
                <button className="btn btn-danger" type="button" id="button-remove" onClick={ removeStock }><i className="bi-trash"></i> Remove</button>
            </div>
        </div>
        <div className="table-responsive grid-content overflow-y-scroll px-2">
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-primary navbar-dark sticky-top col-1">Symbol</th>
                        <th className="table-primary navbar-dark sticky-top col-2">Name</th>
                        <th className="table-primary navbar-dark sticky-top col-1">Quote type</th>
                        <th className="table-primary navbar-dark sticky-top col-1">Currency</th>
                        <th className="table-primary navbar-dark sticky-top col-1">Sector</th>
                        <th className="table-primary navbar-dark sticky-top col-2">Industry</th>
                        <th className="table-primary navbar-dark text-end sticky-top col-1">History<br/>[# rows]</th>
                        <th className="table-primary navbar-dark text-end sticky-top col-1">Updated at</th>
                        <th className="table-primary navbar-dark text-end sticky-top col-2"><i className="bi-lightning"></i>&nbsp;Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { readingStocks ? <tr key="singleline"><td colSpan="9"><div  className="alert alert-warning text-center m-2">Reading data</div></td></tr> :
                    stocksList.map(stock => <tr key={stock.symbol}>
                        <td>
                            <Link to={ "/prices/" + stock.symbol}>{stock.symbol}</Link>
                        </td>
                        <td>{stock.description}</td>
                        <td>{stock.quoteType}</td>
                        <td>{stock.currency}</td>
                        <td>{stock.sector}</td>
                        <td>{stock.industry}</td>
                        <td className="text-end">{stock.historySize}</td>
                        <td className="text-end">{stock.lastUpdate ? stock.lastUpdate.substr(0, 10) : 'Never'}</td>
                        <td className="text-end">
                            <button className="btn btn-sm btn-warning" onClick={() => forceFullDownload(stock.symbol, "600")} title="downlaod last 300d"><i className="bi-arrow-repeat"></i></button>&nbsp;
                            <button className="btn btn-sm btn-success" onClick={() => forceFullDownload(stock.symbol, "10")}  title="downlaod last 10d"><i className="bi-arrow-repeat"></i></button>&nbsp;
                            <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#graphModal" data-graph-symbol={stock.tvSymbol} onClick={() => showDailyGraph(stock.tvSymbol) } disabled={stock.tvSymbol === "undefined"}>
                                <i className="bi-graph-up"></i>
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        <div className="modal fade" ref={modalSymbol} id="graphModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Graph {viewSymbol} </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        { viewSymbol !=="" ?
                            <AdvancedRealTimeChart
                                autosize = {false}
                                symbol = { viewSymbol }
                                interval = "D"
                                timezone = "Etc/UTC"
                                theme = "light"
                                locale = "it"
                                enable_publishing = {false}
                                allow_symbol_change = "false"
                                hide_legend = "true" /> : "" }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default StocksList;