import "./indexcss.css";

const GlobalAssetAllocation = (props) => {

    const formatDataMini = (label, short, long) => {
        return <div  className="p-2 d-flex flex-row justify-content-center w-100 mb-5">
        <table className="col-6 bg-light">
            <thead>
                <tr>
                    <th className="col-4 py-3 text-center">Risk on/Risk off</th>
                    <th className="col-4 py-3 text-center">Posizione lunga (270gg)</th>
                    <th className="col-4 py-3 text-center">Posizione breve (90gg)</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th className="text-right" dangerouslySetInnerHTML={{ __html: label}}></th>
                    <th><div className={"alert text-center " + short}>{short}</div></th>
                    <th><div className={"alert text-center " + long}>{long}</div></th>
                </tr>
            </thead>
        </table>
        </div>
    }

    const formatData = (pos, categoryArray, labelArray, shortArray, longArray) => {

        const realLabelArray = labelArray.filter( (element, index) => (index > 3) && (element !== "") );
        const realCategoryArray = categoryArray.filter( (element, index) => (index > 3) && (element !== "") );
        const realShortArray = shortArray.filter( (element, index) => (index > 3) && (element !== "") );
        const realLongArray = longArray.filter( (element, index) => (index > 3)  && (element !== "") );
        const title = labelArray[4];

        return <div  className="p-2 d-flex flex-row justify-content-center w-100 mb-5 table-responsive">
            <table className="table w-75">
                <thead>
                    <tr>
                        <th className="col-3 py-3 text-center bg-light sticky-top"></th>
                        <th className="col-3 py-3 text-center bg-light sticky-top">{title}</th>
                        <th className="col-3 py-3 text-center bg-light sticky-top">Posizione lunga (270gg)</th>
                        <th className="col-3 py-3 text-center bg-light sticky-top">Posizione breve (90gg)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        realLabelArray.map( (element, index) => {
                            return <tr key={index}>
                                <td className="text-right" dangerouslySetInnerHTML={{ __html: realCategoryArray[index]}}></td>
                                <td className="text-right" dangerouslySetInnerHTML={{ __html: element}}></td>
                                <td><div className={"alert text-center " + realShortArray[index]}>{realShortArray[index]}</div></td>
                                <td><div className={"alert text-center " + realLongArray[index]}>{realLongArray[index]}</div></td>
                            </tr>
                        } )
                    }
                </tbody>
            </table>
        </div>
    }

    if (props.data) {
        let data = props.data;
        const limit = Math.floor(data.length / 5);

        const intList = Array.from({length:limit},(v,k)=>k);

        return <>
            <div id="carouselMini" className="grid-row-start-3 grid-row-end-4 grid-column-start-1 grid-column-end-3 d-flex flex-column overflow-x-auto flex-nowrap carousel slide carousel-fade carousel-dark px-5 border-bottom border-secondary">
                <div className="carousel-inner">
                    {
                    intList.map( k => {
                        return <div className={"carousel-item bg-light " + (k===1 ? " active" : "")} key={k}>
                            {formatDataMini(data[(k*5)+2][1], data[(k*5)+4][1], data[(k*5)+3][1])}
                        </div>
                    })
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselMini" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon text-black" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselMini" data-bs-slide="next">
                    <span className="carousel-control-next-icon text-black" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="grid-gas">
                <div id="carouselFull" className="carousel slide carousel-fade carousel-dark px-5 align-items-top grid-row-start-1 grid-row-end-2 grid-column-start-1 grid-column-end-2">
                    <div className="carousel-inner">
                    {
                        intList.map( k => {
                            return <div className={"carousel-item bg-light" + (k===1 ? " active" : "")} key={k}>
                                { formatData(k, data[(k*5)+1], data[(k*5)+2], data[(k*5)+4], data[(k*5)+3]) }
                            </div>})
                    }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselFull" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon text-black" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselFull" data-bs-slide="next">
                        <span className="carousel-control-next-icon text-black" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    }
    return <div className="alert alert-info text-center align-middle"><div className="spinner-border me-2"></div> <div>Loading data ...</div></div>;
} 

export default GlobalAssetAllocation;