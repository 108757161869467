import axios from "axios";
import { useEffect, useState } from "react";
import { baseUri } from "../commoms";

const Utils = (props) => {

    const [lastRunList, setLastRunList] = useState([]);

    const downloadResponse = (response, filename) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.setAttribute('content-type', 'text/csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const getUpdated = () => {
        axios.get(baseUri + '/utils?report=updated')
            .then(
                (response) => {
                    downloadResponse(response, 'upated.csv');
                }
            )
    }

    const getPricesMap = () => {
        axios.get(baseUri + '/utils?report=prices')
            .then(
                (response) => {
                    downloadResponse(response, 'price-map.csv');
                }
            )
    }

    const getCalculatedMap = () => {
        axios.get(baseUri + '/utils?report=calc')
            .then(
                (response) => {
                    downloadResponse(response, 'calculated-map.csv');
                }
            )
    }

    const getLastRun = () => {
        axios.get(baseUri + '/utils?report=tab_lastrun')
            .then(
                (response) => {
                    console.log(response);
                    setLastRunList(response.data);
                }
            )
    }

    useEffect(() => {
        getLastRun();
    }, []);

    return <>
        <div className="bg-light grid-service d-flex flex-row py-2 justify-content-between px-2">
            <div className="fw-bold fs-2 d-flex flex-row"><i className="bi-gear-wide-connected me-1"></i> Utilities</div>
        </div>
        <div class="responsive-table px-2 grid-content">
            <div className="d-flex flex-row justify-content-between px-2 mt-2">
                <button className="btn btn-lg  btn-primary" onClick={getUpdated}><i className="bi-file-earmark-excel"></i> Last updates</button>
                <button className="btn btn-lg btn-primary" onClick={getPricesMap} ><i className="bi-file-earmark-excel"></i> Prices map</button>
                <button className="btn btn-lg btn-primary" onClick={getCalculatedMap} ><i className="bi-file-earmark-excel"></i> Data map</button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th className="sticky-top">Script</th>
                        <th className="sticky-top">Last run on</th>
                    </tr>
                </thead>
                <tbody>
                    {lastRunList?.map(lr => <tr key={lr.name}>
                        <td>{lr.name}</td>
                        <td>{lr.lastRun}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </>
}

export default Utils;