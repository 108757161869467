import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import logo from '../assets/logo.png';
import { NavLink } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseUri } from '../commoms';

const Header = () => {

  // let displayMenu = true;

  const roles = [
    "ROLE_FOREX",
    "ROLE_COMMO",
    "ROLE_CRYPT",
    "ROLE_FTSEM",
    "ROLE_INDEX",
    "ROLE_ITALY",
    "ROLE_NA100",
    "ROLE_SP100",
    "ROLE_SP500",
  ];

  const { auth } = useAuth();

  const [alive, setAlive] = useState("not connected");

  const checkSetState = () => {
      axios.get(baseUri + "/utils?report=__alive__", { headers: { "Accept": "application/json" } })
        .then(res => {
          setAlive("alive");
        })
        .catch(exp => {
          setAlive("not connected");
        });
  };

  useEffect(() => checkSetState(), []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      checkSetState();
    }, 7 * 1000);
    return () => clearInterval(interval);
  }, []);

    
  return (
    <nav className="grid-header navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container-fluid">
      <div className="navbar-brand d-flex flex-row align-items-center">
        <img src={logo} alt="The Trading Playground - Wyckoff Position Index" className="half-image mr-3" />
        <div className="ms-3 display-7">Wyckoff Position Index</div>
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {auth?.isAuthenticated ?
              <>
                {auth?.isAdministrator ?
                  <>
                    <li className="nav-item">
                      <NavLink to="/stocks" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page" href="#"><i className="bi-graph-up me-1"></i> Instruments</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/schedules" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page" href="#"><i className="bi-clock-history me-1"></i> Scheduler</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/groups" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page" href="#"><i className="bi-collection me-1"></i> Groups</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/scripts" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page" href="#"><i className="bi-journal-code me-1"></i> R scripts</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/utils" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page"><i className="bi-gear-wide-connected me-1"></i> Utilities</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/video-player" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page"><i className="bi-film me-1"></i> TTP TV</NavLink>
                    </li>
              </>:""
                }
                <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn btn-flat" rel="link" data-bs-toggle="dropdown" aria-current="page" >Dashboard</button>
                      <ul className="dropdown-menu z-index-front">
                        { (true || auth?.roles.includes("ROLE_SEASONALITIES") || auth?.isAdministrator) ? <>
                          <li className="dropdown-item">
                            <NavLink to="/wpichart"><i className="bi-display me-1"></i> WPI Chart</NavLink>
                          </li>
                          <li className="dropdown-divider"></li>
                          </>
                          :""
                        }
                        { (auth?.roles.filter(element => roles.includes(element)).length > 0 || auth?.isAdministrator) ? <>
                          <li className="dropdown-item">
                            <NavLink to="/dashboard"><i className="bi-speedometer me-1"></i> Monitor</NavLink>
                          </li>
                          </>
                          :""
                        }
                        { (true || auth?.roles.includes("ROLE_SEASONALITIES") || auth?.isAdministrator) ? <>
                          <li className="dropdown-divider"></li>
                          <li className="dropdown-item">
                            <NavLink to="/seasonalities"><i className="bi-calendar me-1"></i> Best
                              seasonalities</NavLink>
                          </li>
                          </>
                          :""
                        }
                        { (auth?.roles.includes("ROLE_SEASONALITIES") || auth?.isAdministrator) ? <>
                          <li className="dropdown-item">
                            <NavLink to="/seasonalities"><i className="bi-calendar-range-fill me-1"></i> Seasonal
                              calendar</NavLink>
                          </li>
                          <li className="dropdown-item">
                            <NavLink to="/seasonal-wallet"><i className="bi-wallet me-1"></i> Seasonal
                              wallet</NavLink>
                          </li>
                          <li className="dropdown-item">
                            <NavLink to="/seasonal-wallet-performance"><i className="bi-wallet2 me-1"></i> Seasonal
                              wallet performance</NavLink>
                          </li>
                          </>
                          : ""
                        }
                        {(auth?.isAdministrator) ? <>
                              <li className="dropdown-divider"></li>
                              <li className="dropdown-item">
                            <span className=""><strong><i className={"bi-shield me-2"}></i>Legacy tools (only for administrators)</strong></span>
                          </li>
                          <li className="dropdown-item">
                            <a target="_blank" href="/static/displayno45.html" className="" aria-current="page"><i className="bi-display me-1"></i> Display</a>
                          </li>
                          <li className="dropdown-item">
                            <a target="_blank" href="/static/display.html" className="" aria-current="page"><i className="bi-display me-1"></i> Display rot CCW</a>
                          </li>
                          </>
                          :""
                        }
                      </ul>
                    </li>
              </> : ""
          }
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0">
            {auth?.isAuthenticated ?
              <>
                <li className="navbar-text text-me-2 text-white fw-bold"><i className="bi-person-circle me-1"></i>{auth?.username}</li>
                <li className='nav-item'>
                  <NavLink to="/logout" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page"><i className="bi-box-arrow-right me-1"></i> Logout</NavLink>
                </li>
              </> :
              <li className='nav-item'>
                <NavLink to="/login" className={({ isActive }) => { return isActive ? "nav-link active" : "nav-link"; }} aria-current="page"><i className="bi-shield-lock me-1"></i>Login</NavLink>
              </li>
            }
            <li className="nav-item">
              {alive === "alive" ?
                <div className="navbar-text bg-success text-light px-2 rounded">
                  <i className="bi-wifi"></i>
                </div> :
                <div className="navbar-text bg-danger text-light px-2 rounded">
                  <i className="bi-wifi-off"></i>
                </div>
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header;