import { useState, useEffect } from "react";
import "./indexcss.css";

const DataTables = (props) => {

    const data = [ ...props.data];
    // let sortedData = [];
    const type = props.type;


    // const [sortCommands, setSortCommands] = useState([]);
    // // const [sortedData, setSortedData] = useState([... props.data]);
    const loadGraph = props.loadGraph;
    const doSort = props.doSort;
    const sortCommands = props.sortCommands;

    // const traspose = matrix => {if (matrix[0]) return matrix[0].map((x,i) => matrix.map(x => x[i])); else return matrix;};

    // const sortData = () => {
    //     console.log("START SORTING!");
    //     if (sortCommands.length > 100) {
    //         console.log("Sorting rule for ", sortCommands);
    //         const column = sortCommands[0] +1;
    //         const direction = sortCommands[1];
    //         let localData = [...data];
    //         let localSortedData = traspose(traspose(localData).sort( (a,b) => {
    //             if ( a[column] === b[column])
    //                 return 0;
    //             else if ( a[column] > b[column])
    //                 return direction;

    //             return -direction;
    //         }));
    //         setSortedData({...sortedData, ...localSortedData});
    //         console.log(sortedData);
    //     } else {
    //         console.log("No sorting rule!");

    //         setSortedData({...sortedData, ...data});
    //     }
    // };

    // const doSort = (sortArray) => {
    //     setSortCommands(sortArray);
    // };

    const compareArrays = (a,b) => {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    const renderHeaders = () => {
        switch(type) {
            case 2:
            case 3:
            case 4:
            case 7:
            case 8:
            case 10:
                return <thead>
                    <tr>
                        <th className="border-bottom sticky-top p-1 bg-light text-center">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 0 ? "bg-info text-white": "")}>Settore</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([0,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 0 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([0,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 1 ? "bg-info text-white": "")}>Strumento</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([1,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 1 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([1,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 2 ? "bg-info text-white": "")}>Nome strumento</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([2,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 2 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([2,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 3 ? "bg-info text-white": "")}>Forza relativa</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([3,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 3 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([3,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 4 ? "bg-info text-white": "")}>Forza relativa su settore</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([4,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 4 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([4,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 5 ? "bg-info text-white": "")}>Posizione lunga (270gg)</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([5,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 5 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([5,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 6 ? "bg-info text-white": "")}>Posizione breve (90gg)</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([6,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 6 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([6,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 7 ? "bg-info text-white": "")}>Segnale giornaliero</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([7,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 7 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([7,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 8 ? "bg-info text-white": "")}>Segnale settimanale</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([8,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([8,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 8 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([8,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([8,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 9 ? "bg-info text-white": "")}>Segnale mensile</div>
                            <button className={"btn btn-sm p-0 py-1 " + (compareArrays([9,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([9,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 9 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([9,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([9,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light"></th>
                    </tr>
                </thead>
            case 9:
                    return <thead>
                        <tr>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 0 ? "bg-info text-white": "")}>Mercato</div>
                                <button className={"btn btn-sm p-0 py-1 " + (compareArrays([0,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 0 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([0,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 1 ? "bg-info text-white": "")}>Nome Strumento</div>
                                <button className={"btn btn-sm p-0 py-1 " + (compareArrays([1,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 1 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([1,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 2 ? "bg-info text-white": "")}>Strumento</div>
                                <button className={"btn btn-sm p-0 py-1 " + (compareArrays([2,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 2 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([2,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 3 ? "bg-info text-white": "")}>Forza relativa</div>
                                <button className={"btn btn-sm p-0 py-1 " + (compareArrays([3,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 3 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([3,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 4 ? "bg-info text-white": "")}>Posizione lunga (270gg)</div>
                                <button className={"btn btn-sm p-0 py-1  " + (compareArrays([4,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 4 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([4,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 5 ? "bg-info text-white": "")}>Posizione breve (90gg)</div>
                                <button className={"btn btn-sm p-0 py-1  " + (compareArrays([5,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 5 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([5,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 6 ? "bg-info text-white": "")}>Segnale giornaliero</div>
                                <button className={"btn btn-sm p-0 py-1  " + (compareArrays([6,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 6 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([6,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 7 ? "bg-info text-white": "")}>Segnale settimanale</div>
                                <button className={"btn btn-sm p-0 py-1  " + (compareArrays([7,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 7 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([7,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light">
                                <div className={"me-1 p-1 " + (sortCommands[0] === 8 ? "bg-info text-white": "")}>Segnale mensile</div>
                                <button className={"btn btn-sm p-0 py-1  " + (compareArrays([8,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([8,1])}><i className="bi-sort-down"></i></button>
                                <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 8 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                                <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([8,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([8,-1])}><i className="bi-sort-up"></i></button>
                            </th>
                            <th className="border-bottom sticky-top p-1 bg-light"></th>
                        </tr>
                    </thead>
            default:
                return <thead>
                    <tr>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 0 ? "bg-info text-white": "")}>Settore</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([0,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 0 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([0,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([0,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 1 ? "bg-info text-white": "")}>Strumento</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([1,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 1 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([1,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([1,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 2 ? "bg-info text-white": "")}>Forza relativa</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([2,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 2 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([2,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([2,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 3 ? "bg-info text-white": "")}>Posizione lunga (270gg)</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([3,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 3 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([3,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([3,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 4 ? "bg-info text-white": "")}>Posizione breve (90gg)</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([4,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 4 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([4,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([4,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 5 ? "bg-info text-white": "")}>Segnale giornaliero</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([5,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 5 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([5,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([5,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 6 ? "bg-info text-white": "")}>Segnale settimanale</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([6,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 6 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([6,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([6,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light">
                            <div className={"me-1 p-1 " + (sortCommands[0] === 7 ? "bg-info text-white": "")}>Segnale mensile</div>
                            <button className={"btn btn-sm p-0 py-1  " + (compareArrays([7,1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,1])}><i className="bi-sort-down"></i></button>
                            <button className="btn btn-sm p-0 py-1 " onClick={()=>doSort([])}><i className={sortCommands[0] === 7 ?  "bi-funnel-fill" : "bi-funnel"}></i></button>
                            <button className={"btn btn-sm p-0 py-1 "+ (compareArrays([7,-1], sortCommands) ? "btn-outline-info": "")} onClick={()=>doSort([7,-1])}><i className="bi-sort-up"></i></button>
                        </th>
                        <th className="border-bottom sticky-top p-1 bg-light"></th>
                    </tr>
                </thead>

        }
    }

    // const renderRows = () => {
    //     console.log("Rendering rows!");
        
    //     if (sortedData.length > 0) {
    //         const indexArray = sortedData[0];
    //         return indexArray.map( (element, index) => {
    //             switch (type) {
    //                 case 2:
    //                 case 3:
    //                 case 4:
    //                 case 7:
    //                 case 8:
    //                 case 10:
    //                         return <tr key={element}>
    //                         <td className="text-center align-middle">{sortedData[1][index]}</td>
    //                         <td className="align-middle">{sortedData[2][index]}</td>
    //                         <td className="align-middle">{sortedData[3][index]}</td>
    //                         <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(sortedData[4][index] / sortedData[4].length * 100) + "%"}}></div></div>{sortedData[4][index]}</td>
    //                         <td className="align-middle"><div className="progress"><div className="progress-bar bg-info text-black" style={{ width: Math.round(sortedData[5][index] / sortedData[5].length * 100) + "%"}}></div></div>{sortedData[5][index]}</td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[6][index]}>{sortedData[6][index]}</div></td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[7][index]}>{sortedData[7][index]}</div></td>
    //                         <td className="align-middle">{sortedData[8][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[8][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[9][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[9][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[10][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[10][index]}</div>:""}</td>
    //                         <td className="align-middle">
    //                             {
    //                                 sortedData[11][index] ?
    //                                     <button className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={sortedData[2][index]} onClick={() => loadGraph(sortedData[2][index]) }> <i className="bi-bar-chart"></i></button>
    //                                 :""
    //                             }
    //                         </td>
    //                     </tr>
    //                 case 9:
    //                     return <tr key={element}>
    //                         <td className="align-middle text-center">{sortedData[1][index]}</td>
    //                         <td className="align-middle">{sortedData[2][index]}</td>
    //                         <td className="align-middle">{sortedData[3][index]}</td>
    //                         <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(sortedData[4][index] / sortedData[4].length * 100) + "%"}}></div></div>{sortedData[4][index]}</td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[5][index]}>{sortedData[5][index]}</div></td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[6][index]}>{sortedData[6][index]}</div></td>
    //                         <td className="align-middle">{sortedData[7][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[7][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[8][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[8][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[9][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[9][index]}</div>:""}</td>
    //                         <td className="align-middle">
    //                             {
    //                                 sortedData[10][index] ? 
    //                                     <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={sortedData[2][index]} onClick={() => loadGraph(sortedData[2][index]) } >
    //                                         <i className="bi-graph-up"></i>
    //                                     </button>
    //                                 :""
    //                             }
    //                         </td>
    //                     </tr>
    //                 default:
    //                     return <tr key={element}>
    //                         <td className="align-middle text-center">{sortedData[1][index]}</td>
    //                         <td className="align-middle">{sortedData[2][index]}</td>
    //                         <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(sortedData[3][index] / sortedData[3].length * 100) + "%"}}></div></div>{sortedData[3][index]}</td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[4][index]}>{sortedData[4][index]}</div></td>
    //                         <td className="align-middle"><div className={"alert " + sortedData[5][index]}>{sortedData[5][index]}</div></td>
    //                         <td className="align-middle">{sortedData[6][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[6][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[7][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[7][index]}</div>:""}</td>
    //                         <td className="align-middle">{sortedData[8][index] ? <div className="alert alert-warning fw-bold text-center">{sortedData[8][index]}</div>:""}</td>
    //                         <td className="align-middle">
    //                             {
    //                                 sortedData[9][index] ? <>
    //                                     <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={sortedData[2][index]} onClick={() => loadGraph(sortedData[2][index]) } >
    //                                         <i className="bi-graph-up"></i>
    //                                     </button>
    //                                 </>
    //                                 :""
    //                             }
    //                         </td>   
    //                     </tr>                
    //             }
    //         });
    //     } else {
    //         return <tr><td colSpan={10}><div className="alert alert-info text-center"><strong>LOADING</strong></div></td></tr>;
    //     }

    // }

    const renderRows = () => {
        console.log("Rendering rows!");
        
        if (data.length > 0) {
            const indexArray = data[0];
            return indexArray.map( (element, index) => {
                switch (type) {
                    case 2:
                    case 3:
                    case 4:
                    case 7:
                    case 8:
                    case 10:
                            return <tr key={element}>
                            <td className="text-center align-middle">{data[1][index]}</td>
                            <td className="align-middle">{data[2][index]}</td>
                            <td className="align-middle">{data[3][index]}</td>
                            <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(data[4][index] / data[4].length * 100) + "%"}}></div></div>{data[4][index]}</td>
                            <td className="align-middle"><div className="progress"><div className="progress-bar bg-info text-black" style={{ width: Math.round(data[5][index] / data[5].length * 100) + "%"}}></div></div>{data[5][index]}</td>
                            <td className="align-middle"><div className={"alert " + data[6][index]}>{data[6][index]}</div></td>
                            <td className="align-middle"><div className={"alert " + data[7][index]}>{data[7][index]}</div></td>
                            <td className="align-middle">{data[8][index] ? <div className="alert alert-warning fw-bold text-center">{data[8][index]}</div>:""}</td>
                            <td className="align-middle">{data[9][index] ? <div className="alert alert-warning fw-bold text-center">{data[9][index]}</div>:""}</td>
                            <td className="align-middle">{data[10][index] ? <div className="alert alert-warning fw-bold text-center">{data[10][index]}</div>:""}</td>
                            <td className="align-middle">
                                {
                                    data[11][index] ?
                                        <button className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[2][index]) }> <i className="bi-bar-chart"></i></button>
                                    :""
                                }
                            </td>
                        </tr>
                    case 9:
                        return <tr key={element}>
                            <td className="align-middle text-center">{data[1][index]}</td>
                            <td className="align-middle">{data[2][index]}</td>
                            <td className="align-middle">{data[3][index]}</td>
                            <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(data[4][index] / data[4].length * 100) + "%"}}></div></div>{data[4][index]}</td>
                            <td className="align-middle"><div className={"alert " + data[5][index]}>{data[5][index]}</div></td>
                            <td className="align-middle"><div className={"alert " + data[6][index]}>{data[6][index]}</div></td>
                            <td className="align-middle">{data[7][index] ? <div className="alert alert-warning fw-bold text-center">{data[7][index]}</div>:""}</td>
                            <td className="align-middle">{data[8][index] ? <div className="alert alert-warning fw-bold text-center">{data[8][index]}</div>:""}</td>
                            <td className="align-middle">{data[9][index] ? <div className="alert alert-warning fw-bold text-center">{data[9][index]}</div>:""}</td>
                            <td className="align-middle">
                                {
                                    data[10][index] ? 
                                        <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[2][index]) } >
                                            <i className="bi-graph-up"></i>
                                        </button>
                                    :""
                                }
                            </td>
                        </tr>
                    default:
                        return <tr key={element}>
                            <td className="align-middle text-center">{data[1][index]}</td>
                            <td className="align-middle">{data[2][index]}</td>
                            <td className="align-middle"><div className="progress"><div className="progress-bar bg-warning text-black" style={{ width: Math.round(data[3][index] / data[3].length * 100) + "%"}}></div></div>{data[3][index]}</td>
                            <td className="align-middle"><div className={"alert " + data[4][index]}>{data[4][index]}</div></td>
                            <td className="align-middle"><div className={"alert " + data[5][index]}>{data[5][index]}</div></td>
                            <td className="align-middle">{data[6][index] ? <div className="alert alert-warning fw-bold text-center">{data[6][index]}</div>:""}</td>
                            <td className="align-middle">{data[7][index] ? <div className="alert alert-warning fw-bold text-center">{data[7][index]}</div>:""}</td>
                            <td className="align-middle">{data[8][index] ? <div className="alert alert-warning fw-bold text-center">{data[8][index]}</div>:""}</td>
                            <td className="align-middle">
                                {
                                    data[9][index] ? <>
                                        <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[2][index]) } >
                                            <i className="bi-graph-up"></i>
                                        </button>
                                    </>
                                    :""
                                }
                            </td>   
                        </tr>                
                }
            });
        } else {
            return <tr><td colSpan={10}><div className="alert alert-info text-center"><strong>LOADING</strong></div></td></tr>;
        }

    }

    const formatData = () => {


        return <table className="table">
            {
                renderHeaders()
            }
            <tbody>
            {
                renderRows()
            }
            </tbody>
        </table>
    }

    if (data.length > 0) {
        return <>
            <div className="p-5 w-100 overflow-hide">
                <div className="d-flex flex-row overflow-x-scroll">
                    { formatData() }
                </div>
            </div>
        </>
    }
    return <div className="alert alert-info text-center align-middle"><div className="spinner-border me-2"></div> <div>Loading data ...</div></div>;
}

export default DataTables;