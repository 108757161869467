import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import StocksList from './pages/StocksList';
import PricesList from './pages/PricesList';
import SchedulesList from './pages/SchedulesList';
import ScheduleAdd from './pages/ScheduleAdd';
import ScriptsList from "./pages/ScriptsList";
import ScriptAdd from "./pages/ScriptAdd";
import DatasConsole from './pages/DatasConsole';
import DataList from './pages/DataList';
import DataListML from './pages/DataListML';
import GroupList from './pages/GroupList';
import GroupEdit from './pages/GroupEdit';
import Utils from './pages/Utils';
import WPIChart from './pages/WPIChart';
import Login from './pages/Login';
import Unauthorized from './pages/Unauthorized';
import Missing from './pages/Missing';
import RequireAuth from './components/RequireAuth';
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
import RenderHTML from './pages/RenderHTML';
import Landing from './pages/Landing';
import SeasonalitiesTable from './pages/SeasonalitiesTables';
import SeasonalWallet from "./pages/SeasonalWallet";
import SeasonalWalletPerformance from "./pages/SeasonalWalletPerformance";
import VideoPlayer from './pages/VideoPlayer';

function App() {

  return (
      <div className="App vh-100">
        <Header />
        <Routes>
          <Route element={<RequireAuth />}>
            <Route exact path="/" element={<Landing/>} />
            <Route exact path="/stocks" element={ <StocksList/> } />
            <Route exact path="/prices/:symbol" element={ <PricesList/> } />
            <Route exact path="/schedules" element={ <SchedulesList/>  } />
            <Route exact path="/scripts" element={ <ScriptsList/> } />
            <Route exact path="/add-schedule" element={ <ScheduleAdd/> } />
            <Route exact path="/add-script" element={ <ScriptAdd/> } />
            <Route exact path="/datas" element={ <DatasConsole/> } />
            <Route exact path="/data-view-bb/:symbol" element={ <DataList/> } />
            <Route exact path="/data-view-ml/:symbol" element={ <DataListML/> } />
            <Route exact path="/groups/" element={ <GroupList/> } />
            <Route exact path="/group-edit/:name" element={ <GroupEdit/> } />
            <Route exact path="/utils" element={ <Utils /> } />
            <Route exact path="/wpichart" element={ <WPIChart /> } />
            <Route exact path="/dashboard" element={ <Dashboard /> } />
            <Route exact path="/seasonalities" element={ <SeasonalitiesTable /> } />
            <Route exact path="/seasonal-wallet" element={ <SeasonalWallet /> } />
            <Route exact path="/seasonal-wallet-performance" element={ <SeasonalWalletPerformance /> } />
            <Route exact path="/display/:page" element={ <RenderHTML /> } />
            <Route exact path="/video-player" element={ <VideoPlayer /> } />
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={ <Logout />} />
          <Route exact path="/unauthorized" element={<Unauthorized/>} />
          <Route exact path="/*" element={<Missing/>} />
        </Routes>
        <Footer/>
      </div>
  );
}

export default App;
