import axios from "axios";
import { useEffect, useRef, useState } from "react";
import * as d3 from 'd3';
import { baseUri } from "../commoms";

const DatasConsole = (props) => {
    
    const valueListX = useRef();
    const valueListY = useRef();
    const symbolsList = useRef();
    const streamLength = useRef();
    const startingDate = useRef();
    const groupSelected = useRef();
    const [xOptions, setXOptions] = useState([]);
    const [yOptions, setYOptions] = useState([]);
    const [groups, setGroups] = useState([]);
    let auto = true;
    let right = 0;
    let left = 0;
    let top = 0;
    let bottom = 0;
    // let dragging = false;
    // let startX = -1;
    // let startY = -1;


    const getGroups = () => {
        axios.get( baseUri + "/groups" , { headers: { "Accept": "application/json" } })
            .then((res) => {
                setGroups(res.data)
            })
            .catch((err) => { console.log(err) });
    }

    const getSymbols = () => {

        axios.get( baseUri + "/groups?id=" +  encodeURIComponent(groupSelected.current.value), { headers: { "Accept": "application/json" } })
            .then((res) => {
                let sl = res.data.map( g => g.symbol).join(',');
                symbolsList.current.value = sl;
            })
            .catch((err) => { console.log(err) });
    }

            // set the dimensions and margins of the graph
    var //margin = { top: 10, right: 30, bottom: 30, left: 60 },
            margin = { top: 0, right: 0, bottom: 0, left: 0 },
            width = 1200 + margin.right + margin.left,
            height = 900 + margin.top + margin.bottom;

        var colorNdx = 0;
        var colors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];

        const markerBoxWidth = 6;
        const markerBoxHeight = 6;
        const refX = markerBoxWidth / 2;
        const refY = markerBoxHeight / 2;
        // const markerWidth = markerBoxWidth / 2;
        // const markerHeight = markerBoxHeight / 2;
        const arrowPoints = [[0, 0], [0, 6], [6, 3]];

        console.log("Appending SVG to the DIV");

        d3.select("#my_dataviz").selectAll("*").remove();
        // append the svg object to the body of the page
        var svg = d3.select("#my_dataviz")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");
        
    svg
        .call(
            d3.zoom().on("zoom", function (event) {
                svg.attr("transform", event.transform)
            })
        );


    const getScritps = () => {
        axios.get( baseUri + "/scripts", { headers: { "Accept": "application/json" } })
            .then((res) => {
                setXOptions( res.data.data );
                setYOptions(res.data.data);
                
            })
            .catch((err) => { console.log(err) });

    }

    const update = (event) => {

        console.log("Start updating!");

        console.log(d3.select('svg'));

        svg.selectAll('*').remove();

        svg
            .append('defs')
            .append('marker')
            .attr('id', 'arrow')
            .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
            .attr('refX', refX)
            .attr('refY', refY)
            .attr('markerWidth', markerBoxWidth)
            .attr('markerHeight', markerBoxHeight)
            .attr('orient', 'auto-start-reverse')
            .append('path')
            .attr('d', d3.line()(arrowPoints))
            .attr('stroke', '#333')
            .attr('fill', '#333');

        const xCode = valueListX.current.value;
        const yCode = valueListY.current.value;
        const symbols = symbolsList.current.value;
        const limit = streamLength.current.value;
        const date = startingDate.current.value;

        console.log("Read data!");

        console.log("DATE", date);

        const uri = baseUri + "/datas?x=" + xCode + "&y=" + yCode + "&limit=" + limit + "&symbols=" + symbols + "&refDate=" + date;


        d3.json(uri)
            .then(
                (data) => {
                    console.log("data read");



                    console.log("AUTO: ", auto);

                    if (auto) {
                        left = data.datas["geometry"]['left'] * 1.10;
                        right = data.datas["geometry"]['right'] * 1.10;
                        top = data.datas["geometry"]['top'] * 1.10;
                        bottom = data.datas["geometry"]['bottom'] * 1.10;
                    }

                    console.log(top, right, bottom, left);
                    console.log(height,);

                    var yRes = height / (top - bottom);
                    var xRes = width / (right - left);

                    var xZero = -left * xRes;
                    var yZero = top * yRes;

                    svg.append("g")
                        .append("path")
                        .attr('d', d3.line()([
                            [0, 0],
                            [width, 0],
                            [width, height],
                            [0, height],
                            [0, 0],
                        ]))
                        .attr('stroke', '#fff')
                        .attr('fill', '#fff');

                    // Add X axis
                    var x = d3.scaleLinear()
                        .domain([left, right])
                        .range([0, width]);
                    svg.append("g")
                        .attr("transform", "translate(0," + yZero + ")")
                        .call(d3.axisBottom(x));

                    // Add Y axis
                    var y = d3.scaleLinear()
                        .domain([bottom, top])
                        .range([height, 0]);
                    svg.append("g")
                        .attr("transform", "translate(" + xZero + ", 0)")
                        // .attr("transform", "translate(-10, 0)")
                        .call(d3.axisLeft(y));
                    
                    
                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [xZero +1, 5],
                            [width - 40, 5],
                            [width - 40, 0],
                            [width -20, 10],
                            [width - 40, 20],
                            [width - 40, 15],
                            [xZero +1, 15],
                            [xZero +1, 5]
                        ]))
                        .attr("stroke", "#00ff00ff")
                        .attr("fill", '#00ff0033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [xZero +1, height - 5],
                            [width - 40, height - 5],
                            [width - 40, height],
                            [width - 20, height - 10],
                            [width - 40, height - 20],
                            [width - 40, height - 15],
                            [xZero +1, height - 15],
                            [xZero +1, height - 5]
                        ]))
                        .attr("stroke", "#00ff00ff")
                        .attr("fill", '#00ff0033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [5, yZero +1],
                            [5, height - 40],
                            [0, height - 40],
                            [10, height - 20],
                            [20, height - 40],
                            [15 , height - 40],
                            [15, yZero +1],
                            [5, yZero +1]
                        ]))
                        .attr("stroke", "#ff0000ff")
                        .attr("fill", '#ff000033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [width - 5, yZero +1],
                            [width - 5, height - 40],
                            [width, height - 40],
                            [width - 10, height - 20],
                            [width - 20, height - 40],
                            [width - 15 , height - 40],
                            [width - 15, yZero +1],
                            [width - 5, yZero +1]
                        ]))
                        .attr("stroke", "#ff0000ff")
                        .attr("fill", '#ff000033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [width - 5, yZero -1],
                            [width - 5, 40],
                            [width, 40],
                            [width - 10, 20],
                            [width - 20, 40],
                            [width - 15 , 40],
                            [width - 15, yZero - 1],
                            [width - 5, yZero -1]
                        ]))
                        .attr("stroke", "#00ff00ff")
                        .attr("fill", '#00ff0033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [5, yZero -1],
                            [5, 40],
                            [0, 40],
                            [10, 20],
                            [20, 40],
                            [15 , 40],
                            [15, yZero - 1],
                            [5, yZero -1]
                        ]))
                        .attr("stroke", "#00ff00ff")
                        .attr("fill", '#00ff0033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [xZero -1, height - 5],
                            [40, height - 5],
                            [40, height],
                            [20, height - 10],
                            [40, height - 20],
                            [40, height - 15],
                            [xZero - 1, height - 15],
                            [xZero -1, height - 5]
                        ]))
                        .attr("stroke", "#ff0000ff")
                        .attr("fill", '#ff000033');

                    svg.append('g')
                        .append('path')
                        .attr('d', d3.line()([
                            [xZero -1, 5],
                            [40, 5],
                            [40, 0],
                            [20, 10],
                            [40, 20],
                            [40, 15],
                            [xZero - 1, 15],
                            [xZero -1, 5]
                        ]))
                        .attr("stroke", "#ff0000ff")
                        .attr("fill", '#ff000033');

                    colorNdx = 0;

                    for (let key in data.datas) {
                        
                        if (key === 'geometry' || key === 'timeline') {
                            continue;
                        }

                        let localdata = data.datas[key].data;
                        console.log(localdata.length);
                        // localdata = localdata.filter((a) => { if (a[1] !== -200 || a[0] !== -200) return a; else return null});
                        localdata = localdata.filter((a) => (a[1] !== -200 || a[0] !== -200) );
                        console.log(localdata.length);
                        
                        svg.append("path")
                            .datum(localdata)
                            .attr("fill", "none")
                            .attr("stroke", colors[colorNdx])
                            .attr("stroke-width", 1.5)
                            .attr("d", d3.line()
                                .x(function (d) { return x(d[1]) })
                                .y(function (d) { return y(d[2]) })
                            )
                            .attr("marker-end", "url(#arrow)");


                        // Add dots
                        svg.append('g')
                            .selectAll("dot")
                            .data(localdata.slice(0, -1))
                            .enter()
                            .append("circle")
                            .attr("cx", function (d) { return x(d[1]); })
                            .attr("cy", function (d) { return y(d[2]); })
                            .attr("r", 2)
                            .style("fill", colors[colorNdx]);
                        
                        svg.append('g')
                            .append('text')
                            .attr('x', x(localdata[localdata.length - 1][1]) - 30)
                            .attr('y', y(localdata[localdata.length - 1][2]) + 12)
                            .style('stroke', "#333")
                            .style('font-size', '70%')
                            .text(key);

                        
                        
                        colorNdx++;
                        
                        colorNdx = colorNdx % 20;
                    }
            

            },);


        event.preventDefault();

    }

    useEffect(() => {
        getScritps();
        getGroups();
        let d = new Date();
        startingDate.current.value = d.toISOString();
    }, []);

    return <>
        <div className=" d-flex flex-rows align-items-stretch my-5">

            <div className="col-2 align-self-stretch pe-1">
                <h2 className="fw-bold"><i className="bi-file-bar-graph ml-1"></i> Datas</h2>
                <h6>Extract dataset for plot</h6>

                <div className="col-12 mb-2">
                    <label>X axis</label>
                    <select className="form-select" ref={valueListX}>
                        {xOptions.map(option => <option key={ "x_" + option.code }value={option.code}>{option.code}</option>)}
                    </select>
                </div>
                <div className="col-12 mb-2">
                    <label>Y axis</label>
                        <select className="form-select" ref={valueListY}>
                        {yOptions.map(option => <option key={ "y_" + option.code}value={option.code}>{option.code}</option>)}
                    </select>
                </div>
                <div className="col-12 mb-2">
                    <label>Groups</label>
                    <select className="form-select" ref={groupSelected}  onChange={ getSymbols }>
                        {groups.map(group => <option key={group.name} value={group.name}>{group.name}</option>)}
                    </select>
                </div>
                <div className="col-12 mb-2">
                    <label>Symbols list</label>
                    <input className="form-control" ref={symbolsList}/>
                </div>
                <div className="col-12 mb-2">
                    <label>Stream length</label>
                    <input type="range" className="form-range" min="2" max="20" ref={streamLength} onChange={ update }/>
                </div>
                <div className="col-12 mb-2">
                    <label>Starting date</label>
                    <input type="date" className="form-control" ref={startingDate} onChange={update} defaultValue="14/10/2021" />
                </div>
                <div className="col-12 mb-2">
                    <button className="btn btn-primary" onClick={update}><i className="bi-gear-wide-connected me-2"></i>Update</button>
                </div>
            </div>
            <div className="col-10  align-self-stretch">
                <div id="my_dataviz" className=""></div>
            </div>
        </div>
    </>
}

export default DatasConsole;