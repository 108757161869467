import { Navigate } from "react-router-dom";

const Unauthorized = () => {

    return <div className="alter alert-warning">
        <h1 className="display-1">Unauthorized</h1>
        <p>You have not enough permission to access this content.</p>
        <p>Please <Navigate to="/">back</Navigate> to homepage.</p>
    </div>;

}

export default Unauthorized