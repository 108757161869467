import { Schedule } from '../models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUri } from '../commoms';

function ScheduleApp() {

    const [schedule, setSchedule] = useState(new Schedule());

    const navigate = useNavigate();

    const changed = (event) => {
        var target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        var name = target.name;
        var newSchedule = new Schedule();
        newSchedule = schedule;
        newSchedule[name] = value;
        setSchedule(newSchedule);
    }

    const saveSchedule = (event) => {

        axios.put(baseUri + "/schedules", schedule, { headers: { "Accept": "application/json" } })
            .then((res) => {
                console.log("saved");
                navigate('/schedules');
            })
            .catch((err) => { console.log(err) });
        event.preventDefault();
        
    };

    return <div className="h-100 d-flex flex-column justify-content-start align-content-start my-5">
        <h1 className="fw-bold"><i className="bi-clock-history ml-1"></i> New Schedule</h1>
        <form className="form" onSubmit={ saveSchedule }>
            <div className="mb-3">
                <div className="form-check form-switch me-auto">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="interalJob"
                        name="internalJob"
                        onChange={changed}
                        value="false"
                    ></input>
                    <label className="form-check-label" htmlFor="internalJob">Internal Job</label>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="scriptCode">Script or internal job code</label>
                <input
                    type="text"
                    className="form-control"
                    id="scriptCode"
                    name="scriptCode"
                    placeholder="Script code or getAllTickers, updateStockInfoAll , updatePrices for internal jobs"
                    onChange={changed}></input>
            </div>
            <div className="mb-3">
                <label htmlFor="scriptCode">Parameters</label>
                <input
                    type="text"
                    className="form-control"
                    id="params"
                    name="params"
                    placeholder="List of parameters"
                    onChange={changed}></input>
            </div>
            <div className="mb-3">
                    <label>Rule</label>
            </div>
            <div className="mb-1 row">
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="year" name="year" placeholder="year" onChange={changed} ></input>
                        <span className="input-group-text">Y</span>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="month" name="month" placeholder="month" onChange={changed} ></input>
                        <span className="input-group-text">M</span>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="date" name="date" placeholder="date" onChange={changed} ></input>
                        <span className="input-group-text">D</span>
                    </div>
                </div>
            </div>
            <div className="mb-1 row">
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input className="form-control" id="dayOfWeek" name="dayOfWeek" placeholder="dow" onChange={changed} ></input>
                        <span className="input-group-text">dow</span>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="week" name="week" placeholder="week" onChange={changed} ></input>
                        <span className="input-group-text">W</span>
                    </div>
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="hour" name="hour" placeholder="hour" onChange={changed} ></input>
                        <span className="input-group-text">h</span>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="minute" name="minute" placeholder="minute" onChange={changed} ></input>
                        <span className="input-group-text">m</span>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <div className="input-group">
                        <input type="text" className="form-control" id="second" name="second" placeholder="second" onChange={changed} ></input>
                        <span className="input-group-text">s</span>
                    </div>
                </div>
            </div>
            <div className="mb-3 d-flex justify-content-around flex-row">
                <button className="btn btn-primary col-lg-4 col-sm-12">save</button>
                <button className="btn btn-warning col-lg-4 col-cm-12">close</button>
            </div>
        </form>
    </div>;

}

export default ScheduleApp;