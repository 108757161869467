import { Script } from '../models';
import {useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Editor from "@monaco-editor/react";
import axios from 'axios';
import { baseUri } from '../commoms';

const ScriptAdd = (props) =>  {

    const location = useLocation()
    const code = (new URLSearchParams(location.search)).get("code");

    const codeInput = useRef();
    const nameInput = useRef();
    const descriptionInput = useRef();
    const sourceInput = useRef();
    const argsInput = useRef();

    const stderr = useRef();
    const stdout = useRef();



    const getScript = (code) => {
        axios.get(baseUri + "/scripts?code=" + code, { headers: { "Accept": "application/json" } })
            .then((res) => {
                console.log("Got script");
                const script = res.data.data[0];
                codeInput.current.value = script.code;
                nameInput.current.value = script.name;
                descriptionInput.current.value = script.description;
                sourceInput.current.setValue(script.source);
                argsInput.current.value = script.args;

            })
            .catch((err) => { console.log(err) });

    }

    const saveScript = (event) => {

        const script = new Script(codeInput.current.value,
            nameInput.current.value,
            descriptionInput.current.value,
            sourceInput.current.getValue(),
            argsInput.current.value);


        if (code) {
            axios.post(baseUri + "/scripts", script, { headers: { "Accept": "application/json" } })
                .then((res) => {
                    console.log("saved");
                    // history.push('/scripts');
                })
                .catch((err) => { console.log(err) });

        } else {
            axios.put(baseUri + "/scripts", script, { headers: { "Accept": "application/json" } })
                .then((res) => {
                    console.log("saved");
                    // history.push('/scripts');
                })
                .catch((err) => { console.log(err) });

        }
        event.preventDefault();
        
    };

    const handleEditorDidMount = (editor, monaco) => {
        sourceInput.current = editor;
        if (code) {
            getScript(code);
        }
    }

    const runScript = () => {

        const script = new Script(codeInput.current.value,
            nameInput.current.value,
            descriptionInput.current.value,
            sourceInput.current.getValue(),
            argsInput.current.value);
        
        stdout.current.textContent = "Running ...";
        stderr.current.textContent = "Running ...";

        axios.get(baseUri + "/scripts?code=" + script.code + "&action=run", { headers: { "Accept": "application/json" } })
            .then(res => {
                console.log("STDOUT:" + res.data.stdout);
                console.log("STDERR:" + res.data.stderr);
                stdout.current.textContent = "Last run " + new Date() + "\n" + res.data.stdout;
                stderr.current.textContent = "Last run " + new Date() + "\n" + res.data.stderr; 
            })
            .catch(exp => {
                alert(exp);
                console.log(exp);
            });

    }


    console.log("Code: " + code);

    return <>
        <div className="bg-light grid-service d-flex flex-row py-2 justify-content-between px-2">
            <div className="fw-bold fs-2 d-flex flex-row me-5"><i className="bi-clock-history me-2"></i> New Script</div>
        </div>
        <div className="grid-content px-2 py-2">
            <form className="form row" onSubmit={ saveScript }>
                <div className="col-lg-4 col-sm-12">
                    <div className="mb-3">
                        <label htmlFor="code">Code</label>
                        <input type="text" className="form-control" ref={codeInput} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" ref={nameInput} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" ref={descriptionInput}></textarea>
                    </div>
                    <div className="mb-3 d-flex justify-content-around flex-row">
                        <button className="btn btn-primary col-lg-4 col-sm-12"><i className="bi-save"/> save</button>
                        <button className="btn btn-warning col-lg-4 col-cm-12 mx-2"><i className="bi-x-circle"/> close</button>
                        <button className="btn btn-info col-lg-4 col-cm-12" onClick={() => runScript()}><i className="bi-play"/> run</button>
                    </div>
                    <div className="alert alert-info">
                        <h6 className="fw-bold">Last execution output</h6>
                        <pre><code ref={ stdout}></code></pre>
                    </div>
                    <div className="alert alert-danger">
                        <h6 className="fw-bold">Last execution errors</h6>
                        <pre><code ref={ stderr }></code></pre>
                    </div>

                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="mb-3 col-12">
                        <label htmlFor="description">Arguments</label>
                        <input type="text" className="form-control" ref={argsInput}></input>
                    </div>
                    <div className="mb-3 col-12">
                        <label htmlFor="description">Source</label>
                        <Editor className="form-control" defaultLanguage="r" onMount={handleEditorDidMount} height="55vh"/>
                    </div>
                </div>
            </form>
        </div>
    </>;

}

export default ScriptAdd;