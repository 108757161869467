import "./indexcss.css";

const DataTablesCalendar = (props) => {

    const loadGraph = props.loadGraph;
    const tabs = props.tabs;
    const userStatus = JSON.parse(localStorage.getItem("dmuser"));

    const renderHeaders = (type) => {
        if (type < 100) {
            return <thead className="bg-light">
                <tr>
                    <th colSpan={13}><div className="alert alert-danger text-center">Table data mistmatch</div></th>
                </tr>
            </thead>
        } else {
            return <thead className="bg-light">
                <tr>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Data<br/>apertura</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Settore</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Strumento</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Tipo<br/>operazione</th>
                    <th className="border-bottom sticky-top align-top text-end bg-light">Prezzo<br/>ingresso</th>
                    <th className="border-bottom sticky-top align-top text-end bg-light">Stop<br/>loss</th>
                    <th className="border-bottom sticky-top align-top text-end bg-light">Target</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Data<br/>chiusura</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Risultato</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Segnale<br/>intraday</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Segnale<br/>giornaliero</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Segnale<br/>settimanale</th>
                    <th className="border-bottom sticky-top align-top text-center bg-light">Segnale<br/>mensile</th>
                </tr>
            </thead>
        }
    }

    const renderRows = (data, type) => {
        const indexArray = data[0];
        return indexArray.map( (element, index) => {
            if (type < 100) {
                return <tr key={element}>
                    <td colSpan={14}>
                        <div className="alert alert-danger text-center">Table data mistmatch</div>
                    </td>
                </tr>
            } else {
                return <tr key={element}>{
                    data[2][index] === "" ? <td colSpan={14} className="bg-light text-center fw-bold fs-5 sticky-2nd-level">{data[1][index]}</td> :
                    <>
                        <td className="align-middle text-center ">{data[1][index]}</td>
                        <td className="align-middle text-center ">{data[2][index]}</td>
                        <td className="align-middle text-center ">{data[3][index]}</td>
                        <td className="align-middle text-center ">{data[4][index] ? <div className={"alert " + (data[4][index].includes("Buy") ? "RAFFORZAMENTO":"INDEBOLIMENTO")}>{data[4][index]}</div>:""}</td>
                        <td className="align-middle text-end">{data[5][index]}</td>
                        <td className="align-middle text-end">{data[6][index]}</td>
                        <td className="align-middle text-end">{data[7][index]}</td>
                        <td className="align-middle text-center"><strong>{data[8][index]}</strong></td>
                        <td className="align-middle text-center">{data[9][index] ? <div className={"alert " + (data[9][index].includes("STOP") ? "RIBASSISTA":"RIALZISTA")}><strong>{data[9][index]}</strong></div>:""}</td>
                        <td className="align-middle text-center">
                        {
                            data[10][index] ? 
                                <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[10][index].match(/href='([^']*)'/)[1]) } >
                                    <i className="bi-graph-up"></i>
                                </button>
                            :""
                        }
                        </td>
                        <td className="align-middle text-center">
                        {
                            data[11][index] ? 
                                <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[11][index].match(/href='([^']*)'/)[1]) } >
                                    <i className="bi-graph-up"></i>
                                </button>
                            :""
                        }
                        </td>    
                        <td className="align-middle text-center">
                        {
                            data[12][index] ? 
                                <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[12][index].match(/href='([^']*)'/)[1]) } >
                                    <i className="bi-graph-up"></i>
                                </button>
                            :""
                        }
                        </td>
                        <td className="align-middle text-center">
                        {
                            data[13][index] ? 
                                <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#plotModal" data-graph-symbol={data[2][index]} onClick={() => loadGraph(data[13][index].match(/href='([^']*)'/)[1]) } >
                                    <i className="bi-graph-up"></i>
                                </button>
                            :""
                        }    
                        </td>
                    </>
                }
                </tr>
            }
        })

    }

    const formatData = (data, type) => {


        return <table className="table">
            {
                renderHeaders(type)
            }
            <tbody>
            {
                renderRows(data, type)
            }
            </tbody>
        </table>
    }

    const filterData = (data, type) => {
        const indexArray = data[0];

        const filteredTabs = tabs.filter( (t) => { return (userStatus.isAdministrator) || (userStatus.roles.includes(t.role)) });
        let titles = [];

        filteredTabs.map( tab => {
            const currentTitle = tab.label;

            if (!titles.includes(currentTitle))
                titles.push(currentTitle);
        });

        let retData = [];
        
        data.map(col => retData.push([]));

        if (type === 200 || type === 300) {
            let pass = false;
            indexArray.map( (element, index) => {
                if (data[2][index] === "") {
                    if (titles.includes(data[1][index])) {
                        pass = true;
                    } else {
                        pass = false;
                    }
                }

                if (pass) {
                    data.map((forgot, ncol) => {
                        retData[ncol].push(data[ncol][index]);
                    });
                }
            })
        } else {
            retData = data;
        }
        return retData;
    }

    if (props.data.length > 0) {
        const type = props.type;
        let data = filterData(props.data, type);
        

        return <>
            <div className="p-5 w-100 overflow-hide">
                <div className="d-flex flex-row overflow-x-scroll">
                {
                    formatData(data, type)
                }
                </div>
            </div>
        </>
    }
    return <div className="alert alert-info text-center align-middle"><div className="spinner-border me-2"></div> <div>Loading data ...</div></div>;
}

export default DataTablesCalendar;